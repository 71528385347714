import React from "react";

export const NotFoundPage = ({ staticContext = {} }) => {
  // staticContext.notFound will be used in server/index.js
  // notFound property is something you name without any restriction
  // it could have been "PageNoTFounD" for example
  staticContext.notFound = true;
  return <h1>Ops, route not found.</h1>;
};

export default {
  component: NotFoundPage,
};
