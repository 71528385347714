import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import Hero from "./components/hero";
import Story from "./components/story";
import Pastor from "./components/pastor";
import Belief from "./components/belief";
import Staff from "./components/staff";

const About = () => {
  document.title = "About LVD Church | UK";
  return (
    <div>
      <Hero />
      <Story />
      <Pastor />
      <Belief />
      <Staff />
    </div>
  );
};

export default {
  component: withStyles(styles)(About),
};
