import React, { useEffect, useReducer, useCallback } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../global-styles/style.css";
import { api } from "../../../api";

export const ModalSlide = (props) => {
  const MOBILE_MODAL_TRANSITION_CONFIG = {
    OPEN: {
      WIDTH: "100%",
      TRANSFORM: "translateY(0%)",
      BACKGROUNDCOLOR: "rgba(16, 24, 32, 0.7)",
    },
    CLOSE: {
      WIDTH: "100%",
      TRANSFORM: "translateY(100%)",
      BACKGROUNDCOLOR: "rgba(16, 24, 32, 0)",
    },
  };

  const DESKTOP_MODAL_TRANSITION_CONFIG = {
    OPEN: {
      WIDTH: "90%",
      TRANSFORM: "translateX(0%)",
      BACKGROUNDCOLOR: "rgba(16, 24, 32, 0.7)",
    },
    CLOSE: {
      WIDTH: "90%",
      TRANSFORM: "translateX(100%)",
      BACKGROUNDCOLOR: "rgba(16, 24, 32, 0)",
    },
  };
  if (typeof window === "undefined") {
    global.window = {};
  }
  const isMobile = api.utils.isMobile();
  const TRANSITION_CONFIG = isMobile
    ? MOBILE_MODAL_TRANSITION_CONFIG
    : DESKTOP_MODAL_TRANSITION_CONFIG;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      width: TRANSITION_CONFIG.CLOSE.WIDTH,
      transform: TRANSITION_CONFIG.CLOSE.TRANSFORM,
      backgroundColor: TRANSITION_CONFIG.CLOSE.BACKGROUNDCOLOR,
    }
  );

  const closeModal = useCallback(() => {
    changeState("backgroundColor", TRANSITION_CONFIG.CLOSE.BACKGROUNDCOLOR);
    changeState("width", TRANSITION_CONFIG.CLOSE.WIDTH);
    changeState("transform", TRANSITION_CONFIG.CLOSE.TRANSFORM);
    if (api.utils.isMobile()) {
      props.closeModal();
    } else {
      setTimeout(() => {
        props.closeModal();
      }, 500);
    }
  }, [
    TRANSITION_CONFIG.CLOSE.BACKGROUNDCOLOR,
    TRANSITION_CONFIG.CLOSE.WIDTH,
    TRANSITION_CONFIG.CLOSE.TRANSFORM,
    props,
  ]);

  useEffect(() => {
    if (!props.isOpen && state.transform === TRANSITION_CONFIG.OPEN.TRANSFORM) {
      return closeModal();
    }
    changeState("backgroundColor", TRANSITION_CONFIG.OPEN.BACKGROUNDCOLOR);
    changeState("width", TRANSITION_CONFIG.OPEN.WIDTH);
    changeState("transform", TRANSITION_CONFIG.OPEN.TRANSFORM);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.isOpen,
    TRANSITION_CONFIG.OPEN.BACKGROUNDCOLOR,
    TRANSITION_CONFIG.OPEN.WIDTH,
    TRANSITION_CONFIG.OPEN.TRANSFORM,
    closeModal,
  ]);

  function changeState(target, value) {
    setState({ [target]: value });
  }

  const { backgroundColor, width, transform } = state;
  if (api.utils.isMobile()) {
    return props.children;
  }
  return (
    <div
      data-test="modal-background-wrapper"
      id="modal-slide-wrapper-id"
      className="modal-slide-wrapper"
      style={{
        backgroundColor,
      }}
      onClick={props.closeOnBackgroundClick ? () => closeModal() : null}
    >
      <div
        id="modal-slide-content-id"
        className="modal-slide-content"
        style={{ width, transform }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

ModalSlide.defaultProps = {
  closeOnBackgroundClick: true,
};

export default withStyles(styles, globalStyle)(ModalSlide);
