export function insert(char, index, string) {
  if (index > 0)
    return (
      char.substring(0, index) + string + char.substring(index, char.length)
    );
  return string + char;
}

export function getLastCharacter(char) {
  return char.substr(char.length - 1);
}

export function getFirstCharacter(char) {
  return char.charAt(0);
}

export function validateCharacter(char) {
  let _char = char.replace(",", "");
  return isNaN(_char);
}
