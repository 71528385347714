import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import StyleContext from "isomorphic-style-loader/StyleContext";
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import Routes from "./shared/Routes";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import ScrollToTop from "./scrollToTop";
import { init as firebaseInit } from "./config/firebase";

const insertCss = (...styles) => styles.forEach((style) => null);

firebaseInit();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <StyleContext.Provider value={{ insertCss }}>
          <ScrollToTop>
            <div>{renderRoutes(Routes)}</div>
          </ScrollToTop>
        </StyleContext.Provider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
