import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import style from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import Hero from "./components/hero";
import Message from "./components/message";
import MissionDescription from "./components/mission-description";
import Giving from "./components/giving";

const Mission = () => {
  document.title = "Mission | UK";
  return (
    <div>
      <Hero />
      <Message />
      <MissionDescription />
      <Giving />
    </div>
  );
};

export default {
  component: withStyles(style, globalStyle)(Mission),
};
