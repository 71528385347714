import { sendVerificationCode } from "./sendVerificationCode";
import { checkVerificationCode } from "./checkVerificationCode";
import { getUserDetails } from "./getUserDetails";
import { submitPayment } from "./submitPayment";

export const checkout = {
  sendVerificationCode,
  checkVerificationCode,
  getUserDetails,
  submitPayment
};
