import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export const send = (mailObj) => {
  var sendMail = firebase.functions().httpsCallable("sendMail");
  return sendMail(mailObj)
    .then(function (result) {
      if (result.data.message === "Queued. Thank you.") {
        return success();
      } else {
        return fail();
      }
    })
    .catch((err) => {
      console.log(err);
      return fail();
    });
};

function success() {
  return {
    code: 200,
    message: "Prayer request sent!",
  };
}

function fail() {
  return {
    code: 503,
    message: "Ops... Something went wrong, please try again.",
  };
}
