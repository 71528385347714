import React from "react";
import style from "./style.css";
import withStyles from "isomorphic-style-loader/withStyles";
import globalStyle from "../../../../../components/global-styles/style.css";

const _CustomInputText = ({
  label,
  type,
  name,
  borderWidth,
  borderColor,
  backgroundColor,
  placeholder,
  value,
  onChange,
  disabled
}) => {
  return (
    <div className="prayer-request-input-text-wrapper">
      <div className="prayer-request-input-text-label-wrapper flex align-center justify-start">
        <label className="raleway-typography">{label}</label>
      </div>

      <div
        className="prayer-request-input-text center"
        style={{
          border: `${borderWidth} solid ${borderColor}`,
          backgroundColor: backgroundColor
        }}
      >
        <input
          className="raleway-typography"
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{ backgroundColor: backgroundColor }}
        />
      </div>
    </div>
  );
};

const _CustomTextArea = ({
  label,
  placeholder,
  value,
  borderWidth,
  borderColor,
  backgroundColor,
  onChange,
  name,
  disabled
}) => {
  return (
    <div className="prayer-request-input-text-wrapper">
      <div className="prayer-request-input-text-label-wrapper flex align-center justify-start">
        <label className="raleway-typography">{label}</label>
      </div>

      <div
        className="prayer-request-text-area center"
        style={{
          border: `${borderWidth} solid ${borderColor}`,
          backgroundColor: backgroundColor
        }}
      >
        <textarea
          className="raleway-typography"
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

_CustomInputText.defaultProps = {
  type: "text",
  borderWidth: "1px",
  borderColor: "rgb(189, 189, 189)",
  backgroundColor: "white"
};

_CustomTextArea.defaultProps = {
  borderWidth: "1px",
  borderColor: "rgb(189, 189, 189)",
  backgroundColor: "white"
};

export const CustomInputText = withStyles(style, globalStyle)(_CustomInputText);
export const CustomTextArea = withStyles(style, globalStyle)(_CustomTextArea);
