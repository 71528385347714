import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../components/parallax-container";
import hero_img from "../../../../../resources/images/hero/mission-hero.png";

export class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        data-test="mission-hero-wrapper"
        parallaxContainerHeight="100vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.3)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.3)"
        minHeight={500}
      >
        <div
          data-test="mission-hero-content"
          className="missionpage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h1
            data-test="mission-hero-title"
            className="missionpage-hero-section-headline white"
          >
            HELPING THOSE IN NEED
          </h1>
          <h2
            data-test="mission-hero-subtitle"
            className="missionpage-hero-section-subheadline white raleway-typography"
          >
            “Let us not love with words or speech but with actions and in
            truth.”
          </h2>
          <p data-test="mission-hero-subtitle2" className="raleway-typography">
            1 John 3:18
          </p>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
