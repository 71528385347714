export const timestampConverter = (timestamp) => {
  try {
    let date = new Date(timestamp.seconds * 1000);
    let dateToStringArr = date.toDateString().split(" ");
    let month = dateToStringArr[1];
    let day = dateToStringArr[2];
    let year = dateToStringArr[3];
    let formattedDate = month + " " + day + ", " + year;
    let timeToStringArr = date.toTimeString().split(":");
    let hours = timeToStringArr[0];
    let mins = timeToStringArr[1];
    let meridiem = "";
    if (hours < 12) {
      meridiem = "AM";
    } else {
      meridiem = "PM";
    }
    let formattedTime = "@" + hours + ":" + mins + " " + meridiem;
    return {
      date: formattedDate,
      time: formattedTime,
    };
  } catch (err) {
    console.log(err);
    return {
      date: "Not available",
    };
  }
};
