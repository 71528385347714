import React, { useRef, useEffect } from "react";
import styles from "./language-dropdown.css";
import withStyles from "isomorphic-style-loader/withStyles";
import globalStyles from "../../global-styles/style.css";
import { languages } from "../../../../languages/index";

function LanguageDropdown(props) {
  let languageDropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown, false);
    return () => {
      document.removeEventListener("mousedown", closeDropdown, false);
    };
  });

  function closeDropdown(e) {
    if (
      !languageDropdownRef.contains(e.target) &&
      !props.containerRef.contains(e.target)
    ) {
      props.toggleDropdown();
    }
  }
  return (
    <div
      ref={(node) => (languageDropdownRef = node)}
      className="language-dropdown center column"
    >
      {languages.map((language, idx) => (
        <div
          key={idx}
          className="language-dropdown-row"
          onClick={() => props.changeLanguage(language.name)}
        >
          <img
            src={require(`../../../../resources/svg/flags/rounded/${language.img}`)}
            alt="flag"
          />
          <p>{language.code}</p>
          {language.disable ? (
            <div className="language-dropdown-row-disable" />
          ) : null}
          {language.disable ? (
            <p className="language-dropdown-row-disable-text">coming soon</p>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default withStyles(styles, globalStyles)(LanguageDropdown);
