import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import pastAshis from "../../../../../resources/images/pastors/ashis.jpg";
import pastYouth from "../../../../../resources/images/pastors/youth-pastor.png";

const StaffCard = props => {
  return (
    <div className="ap-staff-item">
      <div style={{ backgroundImage: `url(${props.image})` }} />
      <h2 className="raleway-typography">{props.name}</h2>
      <h5 className="raleway-typography">{props.title}</h5>
      <p className="raleway-typography">{props.description}</p>
      <a href={`mailto:${props.email}?`} className="raleway-typography">
        Contact
      </a>
    </div>
  );
};

const Staff = () => {
  return (
    <section className="ap-staff-wrapper center column">
      <div className="ap-staff-header center">
        <h1 className="raleway-typography">Meet our Staff</h1>
      </div>
      <div className="ap-staff-item-wrapper flex align-start justify-around">
        <StaffCard
          image={pastAshis}
          name="Past. Yogesh Jugroop"
          title="Pastor & Worship Leader, London Campus"
          description="Pastor and Worship Leader, Yogesh and his wife Yovani are the lead
      pastors of our London Campus. They have two beautiful daughters."
          email="pastorashis@lvdchurchuk.org"
        />
        <StaffCard
          image={pastYouth}
          name="Past. Shano"
          title="Youth Pastor, LVD Church UK"
          description="With more than 5 years in the ministry, 
          Shano is the Youth pastors of LVD Church in Crawley, UK. 
          His passion is to see the young generation surrended completly 
          to God and equip them to impact their sphere and positively shape our society."
          email="pastorshano@lvdchurchuk.org"
        />
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Staff);
