import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./form.css";
import globalStyle from "../../../components/global-styles/style.css";
import TextInput from "./text-input";

const Form = props => {
  return (
    <section className="gift-aid-form-wrapper flex justify-start align-start">
      <div className="gift-aid-form-content center column">
        {/* NAME */}
        <div className="gift-aid-form-name-wrapper column">
          <div className="gift-aid-form-name-label-wrapper flex align-center justify-start">
            <label>Name *</label>
          </div>
          <div className="gift-aid-form-name-input-wrapper">
            <TextInput
              type="text"
              name="name"
              width={600}
              minWidth={130}
              border={
                props.displayErrorName ? "1px solid red" : "1px solid lightgrey"
              }
              margin="0px 10px 15px 0px" // top right bottom left
              placeholder="Name"
              value={props.name}
              onChange={props.onChange}
              displayError={props.displayErrorName}
            />
            <TextInput
              type="text"
              name="surname"
              width={550}
              minWidth={100}
              border={
                props.displayErrorSurname
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 0px 15px 0px" // top right bottom left
              placeholder="Surname"
              value={props.surname}
              onChange={props.onChange}
              displayError={props.displayErrorSurname}
            />
          </div>
        </div>

        {/* ADDRESS */}
        <div className="gift-aid-form-name-wrapper column">
          <div className="gift-aid-form-name-label-wrapper flex align-center justify-start">
            <label>
              Address: (Required to identify you as a current UK taxpayer) *
            </label>
          </div>
          <div className="gift-aid-form-name-input-wrapper flex column">
            <TextInput
              type="text"
              name="addr1"
              width="100%"
              minWidth="95%"
              margin="0px 0px 15px 0px" // top right bottom left
              border={
                props.displayErrorAddr1
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              placeholder="Address Line 1"
              value={props.addr1}
              onChange={props.onChange}
              displayError={props.displayErrorAddr1}
            />
            <TextInput
              type="text"
              name="addr2"
              width="100%"
              minWidth="95%"
              border={
                props.displayErrorAddr2
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 0px 15px 0px" // top right bottom left
              placeholder="Address Line 2"
              value={props.addr2}
              onChange={props.onChange}
              displayError={props.displayErrorAddr2}
            />
          </div>
          <div className="gift-aid-form-name-input-wrapper flex row">
            <TextInput
              type="text"
              name="city"
              width={550}
              minWidth={130}
              border={
                props.displayErrorCity ? "1px solid red" : "1px solid lightgrey"
              }
              margin="0px 10px 15px 0px" // top right bottom left
              placeholder="City"
              value={props.city}
              onChange={props.onChange}
              displayError={props.displayErrorCity}
            />
            <TextInput
              type="text"
              name="region"
              width={550}
              minWidth={100}
              border={
                props.displayErrorRegion
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 10px 15px 0px" // top right bottom left
              placeholder="Country (UK)"
              value={props.region}
              onChange={props.onChange}
              displayError={props.displayErrorRegion}
            />
            <TextInput
              type="text"
              name="postcode"
              width={550}
              minWidth={100}
              border={
                props.displayErrorPostcode
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 0px 15px 0px" // top right bottom left
              placeholder="Postcode"
              value={props.postcode}
              onChange={props.onChange}
              displayError={props.displayErrorPostcode}
            />
          </div>
        </div>

        {/* EMAIL */}
        <div className="gift-aid-form-name-wrapper column">
          <div className="gift-aid-form-name-label-wrapper flex align-center justify-start">
            <label>Email *</label>
          </div>
          <div className="gift-aid-form-name-input-wrapper">
            <TextInput
              type="email"
              name="email"
              width="100%"
              minWidth="95%"
              border={
                props.displayErrorEmail
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 0px 15px 0px" // top right bottom left
              placeholder="Email"
              value={props.email}
              onChange={props.onChange}
              displayError={props.displayErrorEmail}
            />
          </div>
        </div>

        {/* PHONE */}
        <div className="gift-aid-form-name-wrapper column">
          <div className="gift-aid-form-name-label-wrapper flex align-center justify-start">
            <label>Phone *</label>
          </div>
          <div className="gift-aid-form-name-input-wrapper">
            <TextInput
              type="number"
              name="phone"
              width="100%"
              minWidth="95%"
              border={
                props.displayErrorPhone
                  ? "1px solid red"
                  : "1px solid lightgrey"
              }
              margin="0px 0px 15px 0px" // top right bottom left
              placeholder="+44(0) 7 123 123456"
              value={props.phone}
              onChange={props.onChange}
              displayError={props.displayErrorPhone}
            />
          </div>
        </div>

        {/* CONSENT */}
        <div className="gift-aid-form-name-wrapper column">
          <div className="gift-aid-form-name-label-wrapper flex align-center justify-start">
            <label>Gift Aid Declaration Form</label>
          </div>
          <div className="gift-aid-form-consent-input-wrapper">
            <input
              type="checkbox"
              name="consent"
              checked={props.consent}
              onChange={props.onConsentChange}
            />
            <p
              onClick={props.onConsentChange}
              style={{ color: props.displayErrorConsent ? "red" : "black" }}
            >
              LVD Church is a registered charity in the UK. Charity No. ****** I
              am happy for all gifts of money that I have made to LVD Church UK
              in the past four years and all future gifts of money that I make,
              to be Gift Aid donations. I am a UK taxpayer and understand that
              if I pay less Income Tax & Capital Gains Tax in the current tax
              year than the amount of Gift Aid claimed on all of my donations
              across all Charities, it is my responsibility to pay any
              difference.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Form);
