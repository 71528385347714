import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

export const Message = () => {
  return (
    <section className="pp-message-wrapper center column" data-test="wrapper">
      <p className="raleway-typography" data-test="paragraph">
        We as a church believe in the power of prayer. Every Sunday we pray
        together for the needs of the people in our Church. Share with us what
        we can be praying for and what you’re thankful for.
      </p>
      <h4 className="raleway-typography" data-test="bold-text">
        "LET’S BELIEVE FOR YOUR BREAKTHROUGH"
      </h4>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Message);
