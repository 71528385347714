import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import errorIcon from "../../../../../resources/svg/round-error.svg";
import infoIcon from "../../../../../resources/svg/info.svg";

const InfoBox = (props) => {
  let svg = infoIcon;
  if (props.style === "error") svg = errorIcon;
  return (
    <div
      data-test={props["data-test"]}
      className="infobox-wrapper center"
      style={{
        height: props.height,
        backgroundColor: props.style === "error" ? "red" : "",
      }}
    >
      <div className="infobox-container center">
        {props.style !== "error" ? <img src={svg} alt="warning" /> : null}

        <h4
          className="raleway-typography"
          style={{ color: props.style === "error" ? "white" : "" }}
        >
          {props.text}{" "}
          {props.action !== null ? (
            <span
              data-test="infobox-btn"
              ref={props.inputRef}
              onClick={props.action}
            >
              {props.actionText}
            </span>
          ) : null}
        </h4>
      </div>
    </div>
  );
};

InfoBox.defaultProps = {
  text: "info box text here",
  action: null,
  actionText: "",
  height: 70,
  style: "info",
  "data-test": "infobox-data-test",
};

export default withStyles(styles, globalStyle)(InfoBox);
