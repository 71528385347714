import { useState, useEffect, useCallback } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { api } from "../../../api";

function PaymentHOC(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  const handlePayment = useCallback(async () => {
    try {
      const cardElement = elements.getElement(CardElement);
      const paymentResponse = await api.checkout.submitPayment(
        stripe,
        cardElement,
        props.email,
        props.amount,
        props.selectedCurrency.code,
        props.selectedDonation,
        props.name,
        props.city,
        props.address,
        props.address_line_2,
        props.postcode,
        props.country.code,
        props.selectedLocation
      );
      // console.log(paymentResponse);
      props.renderResponseUI(paymentResponse);
    } catch (exception) {
      console.log(exception);
      return null;
    }
  }, [elements, props, stripe]);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
      handlePayment();
    }
  }, [loading, handlePayment]);

  return null;
}

export default PaymentHOC;
