import React from "react";
import { NavLink } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./styles.css";
import globalStyle from "../global-styles/style.css";

const Footer = () => {
  return (
    <footer className="footer-wrapper center">
      <div className="footer-content center column">
        <div className="footer-content-top flex">
          <div className="footer-logo-wrapper flex align-center justify-start column">
            <h1 className="raleway-typography">LVD CHURCH | UK</h1>
            <p className="raleway-typography">MINISTRIES</p>
            <div className="footer-logos-wrapper center">
              <img
                onClick={() =>
                  (window.location.href =
                    "https://www.facebook.com/LVDChurchUK")
                }
                src={require("../../../resources/svg/facebook.svg")}
                alt="facebook"
              />
              <img
                onClick={() =>
                  (window.location.href =
                    "https://www.youtube.com/channel/UCFhOHtglfQw1fsDkI3FvAmw")
                }
                src={require("../../../resources/svg/youtube.svg")}
                alt="youtube"
              />
            </div>
          </div>
          <div className="footer-links-wrapper flex align-center justify-start column">
            <div className="footer-link-group flex align-center justify-start column">
              <h3>Ministries</h3>
              <NavLink
                className="footer-link raleway-typography"
                exact
                to="/groups"
              >
                Groups
              </NavLink>
              <NavLink
                className="footer-link raleway-typography"
                exact
                to="/prayer-request"
              >
                Prayer Request
              </NavLink>
              <NavLink
                className="footer-link raleway-typography"
                exact
                to="/mission"
              >
                Our Mission
              </NavLink>
            </div>
          </div>

          <div className="footer-links-wrapper flex align-center justify-start column">
            <div className="footer-link-group flex align-center justify-start column">
              <h3>Finance</h3>
              <NavLink
                className="footer-link raleway-typography"
                exact
                to="/giving"
              >
                Giving
              </NavLink>
              <NavLink
                className="footer-link raleway-typography"
                exact
                to="/gift-aid"
              >
                Gift Aid
              </NavLink>
            </div>
          </div>
        </div>
        <div className="footer-content-bottom center column">
          <p>
            Langley Green, Martyrs Avenue Rh11 7RX Crawley | Phone: +44 7825
            838531 | email: pastor.roger@lvdchurchuk.ord
          </p>
          <p>©2023 LVD Church. All Rights Reserved | Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
};

export default withStyles(styles, globalStyle)(Footer);
