import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import PastRoroImg from "../../../../../resources/images/pastors/Past-Roro-and-Wife.jpg";

const Pastor = () => {
  return (
    <section className="ap-pastor-wrapper center column">
      <div className="ap-pastor-content center column">
        <img src={PastRoroImg} alt="pastor" />
        <h1 className="raleway-typography">Roger & Josianne Ancrasamy</h1>
        <h4 className="raleway-typography">Senior Pastor of LVD Church UK</h4>
        <p className="raleway-typography">
          Senior Pastor and Superintendent of LVD UK, Roger and Josiane are the
          lead pastors of LVD Church in Crawley, Uk. With more than 30 years in
          the ministry, they have three beautiful children.
        </p>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Pastor);
