import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

const About = () => {
  return (
    <section className="gp-about-wrapper center column">
      <h1 className="raleway-typography">What we believe</h1>
      <p className="raleway-typography">
        We believe in the biblical principles of tithes and offerings: if
        everyone takes care of the principle of tithes and offerings, it
        provides resource to create a healthy community and organisation.
        <br />
        <br />
        In Malachi, the Bible talks about bringing the first 10% (tithe) of our
        income into the storehouse (church). If you do, “I will open the windows
        of heaven for you. I will pour out a blessing so great you won’t have
        enough room to take it in!”. Let’s believe for God to provide for us as
        a church, as we obey His Word in bringing our tithes.
      </p>
    </section>
  );
};

export default withStyles(styles, globalStyle)(About);
