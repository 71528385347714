import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../../components/parallax-container";
import hero_img from "../../../../../../resources/images/hero/youth-hero.png";

class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        parallaxContainerHeight="60vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.8)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.8)"
        minHeight={500}
      >
        <div className="youthpage-hero-container" ref={this.parallaxContent}>
          <h1 className="youthpage-hero-section-headline white bungeeshade-typography">
            YOUTH
          </h1>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
