import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase/app";
import "firebase/firestore";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import ModalSlide from "../../components/modal-slide";
import Header from "./components/header";
import currency from "./currency.json";
import AmountInput from "./components/amount-input";
import CheckoutFilter from "./components/checkout-filter";
import UserVerificationForm from "./components/user-verification-form";
import UserForm from "./components/user-form";
import countries from "../../components/country-dropdown/countries.json";
import StripeCardElement from "./components/stripe-card-element";
import StatusPage from "./components/checkout-status-page";
import { api } from "../../../api";
import PaymentHOC from "./PaymentHOC";
import { production_mode } from "../../../config/config_variables";

export const DONATIONS = [
  "TITHE & OFFERING",
  "BUILDING FUNDS",
  "MISSION - MADAGASCAR",
];
export const PAYMENT_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
  TIMEDOUT: "timedout",
  DECLINED: "declined",
};

export class Checkout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.LOCATIONS = ["LVD - CRAWLEY", "LVD - LONDON", "LVD - BEWBUSH"];
    this.DONATIONS = DONATIONS;
    this.PAYMENT_STATUS = PAYMENT_STATUS;

    this.TIMEOUT_INSTANCE = "";
    this.TIMEOUT_PERIOD = 4 * 60 * 1000; // 4 mins

    this.main_account = production_mode
      ? loadStripe(process.env.REACT_APP_STRIPE_MAIN_ACCOUNT_PUBLISHABLE_KEY)
      : loadStripe(
          process.env.REACT_APP_TEST_STRIPE_MAIN_ACCOUNT_PUBLISHABLE_KEY
        );
    this.mission_account = production_mode
      ? loadStripe(process.env.REACT_APP_STRIPE_MISSION_ACCOUNT_PUBLISHABLE_KEY)
      : loadStripe(
          process.env.REACT_APP_TEST_STRIPE_MISSION_ACCOUNT_PUBLISHABLE_KEY
        );
    this.building_account = loadStripe(
      process.env.REACT_APP_STRIPE_BUILDING_ACCOUNT_PUBLISHABLE_KEY
    );

    this.FIREBASE_UNSUBSCRIBE = "";
    this.state = {
      stripeCard: null,
      paymentStatus: this.PAYMENT_STATUS.LOADING,
      showStatusPage: false,
      paymentReference: "123",
      additionalMessage: "",
      modalIsOpen: true,
      paymentHOC: false,
      //
      currencyCode: currency[0].code,
      selectedCurrency: currency[0],
      amount: "0.00",
      errorMessage: "",
      selectedLocation: this.LOCATIONS[0],
      selectedDonation: this.DONATIONS[0],
      userVerified: false, // ***** SET TO FALSE BEFORE DEPLOYING TO PRODUCTION
      email: "", //
      emailError: false,
      name: "",
      nameError: false,
      address: "",
      addressError: false,
      address_line_2: "",
      city: "",
      cityError: false,
      postcode: "",
      postcodeError: false,
      country: countries[229],
      userFound: false, // *****
      inputErrorMessage: "",
      closeModalfromBg: true,
    };
  }

  changeState = (target, value) => {
    this.setState({ [target]: value, [`${target}Error`]: false });
    if (target !== "inputErrorMessage") {
      this.setState({ [`inputErrorMessage`]: false });
    }
    if (target === "selectedDonation") {
      this.setState({ [target]: "Loading..." });
      setTimeout(() => {
        this.setState({ [target]: value });
      }, 100);
    }
  };

  handlePayment = async () => {
    try {
      if (this.validatedForm()) {
        this.setState({
          showStatusPage: true,
          paymentStatus: this.PAYMENT_STATUS.LOADING,
          paymentHOC: true,
        });
      }
    } catch (exception) {
      // TODO: handle exception
    }
  };

  renderResponseUI = (paymentResponse) => {
    try {
      // console.log(paymentResponse);
      let responseCode = paymentResponse.code;
      if (responseCode === undefined) {
        // paymentintent successful
        let orderId = paymentResponse.data.metadata.order_id;
        this.setState({ paymentReference: orderId });
        return this.paymentStatusTracker(this.state.email, orderId);
      } else {
        let declinedMessage = paymentResponse.message;
        this.setState({
          additionalMessage: declinedMessage,
          paymentStatus: this.PAYMENT_STATUS.DECLINED,
          closeModalfromBg: true,
        });
      }
    } catch (exception) {
      console.log(exception);
      this.setState({ paymentStatus: this.PAYMENT_STATUS.ERROR });
      clearTimeout(this.TIMEOUT_INSTANCE);
    }
  };

  paymentStatusTracker = (email, orderId) => {
    this.startTimeout();
    try {
      let db = firebase.firestore();
      let _path = `payments/${email}/records/${orderId}`;
      this.FIREBASE_UNSUBSCRIBE = db.doc(_path).onSnapshot((doc) => {
        if (doc.data().status === "succeeded") {
          this.setState({
            paymentStatus: this.PAYMENT_STATUS.SUCCESS,
            closeModalfromBg: true,
          });
          clearTimeout(this.TIMEOUT_INSTANCE);
        }
        if (doc.data().status === "failed") {
          this.setState({
            paymentStatus: this.PAYMENT_STATUS.ERROR,
            closeModalfromBg: true,
          });
          clearTimeout(this.TIMEOUT_INSTANCE);
        }
      });
    } catch (exception) {
      // TODO: handle exception
    }
  };

  startTimeout = () => {
    try {
      this.TIMEOUT_INSTANCE = setTimeout(() => {
        this.FIREBASE_UNSUBSCRIBE();
        this.setState({
          paymentStatus: this.PAYMENT_STATUS.TIMEDOUT,
          closeModalfromBg: true,
        });
      }, this.TIMEOUT_PERIOD);
    } catch (exception) {
      // TODO: better exception
    }
  };

  validatedForm = () => {
    let card = !this.isCardInputError();
    let name = !this.isInputError("name", this.state.name);
    let email = !this.isInputError("email", this.state.email);
    let address = !this.isInputError("address", this.state.address);
    let city = !this.isInputError("city", this.state.city);
    let country = !this.isInputError("country", this.state.country.name);
    let postcode = !this.isInputError("postcode", this.state.postcode);
    let amount = !this.isAmountError(this.state.amount, "0.00");
    return (
      amount && name && email && address && city && country && postcode && card
    );
  };

  isAmountError = (value, compareTo) => {
    if (!api.utils.validateTextInputByComparison(value, compareTo)) {
      return false;
    }
    this.setState({
      inputErrorMessage: `Amount cannot be ${this.state.selectedCurrency.symbol} ${compareTo}`,
    });
    return true;
  };

  isInputError = (target, value) => {
    if (api.utils.validateTextInput(value)) {
      return false;
    }
    this.setState({
      [`${target}Error`]: true,
      inputErrorMessage: "Please fill all fields highlighted in red.",
    });
    return true;
  };

  isCardInputError = () => {
    const { stripeCard } = this.state;
    if (stripeCard !== null && stripeCard.complete) {
      return false;
    }
    this.setState({ inputErrorMessage: "Please complete card details form" });
    return true;
  };

  onChangeStripeCard = (e) => {
    this.setState({ stripeCard: e });
  };

  close = async () => {
    this.setState({ modalIsOpen: false });
  };

  renderStripeElement = () => {
    switch (this.state.selectedDonation) {
      case this.DONATIONS[0]:
        return (
          <Elements
            data-test="elements-main-account"
            stripe={this.main_account}
          >
            {this.stripeElement()}
          </Elements>
        );
      case this.DONATIONS[1]:
        return (
          <Elements
            data-test="elements-building-account"
            stripe={this.building_account}
          >
            {this.stripeElement()}
          </Elements>
        );
      case this.DONATIONS[2]:
        return (
          <Elements
            data-test="elements-mission-account"
            stripe={this.mission_account}
          >
            {this.stripeElement()}
          </Elements>
        );
      default:
        return <p>Loading...</p>;
    }
  };

  stripeElement = () => {
    return (
      <React.Fragment>
        <StripeCardElement
          data-test="stripe-card-element"
          onChangeStripeCard={this.onChangeStripeCard}
        />
        {this.state.inputErrorMessage.length > 0 ? (
          <p
            data-test="form-error-msg"
            className="checkout-error-message center"
          >
            {this.state.inputErrorMessage}
          </p>
        ) : null}
        <div className="checkout-separator" />
        <button
          data-test="submit-btn"
          className="cop-btn center"
          onClick={this.handlePayment}
        >
          GIVE
        </button>
        {this.state.paymentHOC ? (
          <PaymentHOC
            data-test="payment-hoc"
            email={this.state.email}
            amount={Math.round(this.state.amount * 100)}
            selectedCurrency={this.state.selectedCurrency}
            selectedDonation={this.state.selectedDonation}
            name={this.state.name}
            city={this.state.city}
            address={this.state.address}
            address_line_2={this.state.address_line_2}
            postcode={this.state.postcode}
            country={this.state.country}
            selectedLocation={this.state.selectedLocation}
            renderResponseUI={this.renderResponseUI}
          />
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <ModalSlide
        data-test="modal-slide"
        closeModal={this.props.toogleCheckout}
        closeOnBackgroundClick={this.state.closeModalfromBg}
        isOpen={this.state.modalIsOpen}
      >
        <div
          className="checkoutpage-wrapper center column"
          id="checkout-page-id"
          data-test="wrapper"
        >
          <Header data-test="header" closeModal={this.close} />
          <div
            style={{
              width: "100%",
              padding: api.utils.isMobile() ? "0 15px" : "0 30px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <AmountInput
              data-test="amount-input"
              amount={this.state.amount}
              selectedCurrency={this.state.selectedCurrency}
              changeState={this.changeState}
            />
            <div className="checkout-separator" />
            <CheckoutFilter
              data-test="checkout-filter"
              locations={this.LOCATIONS}
              selectedLocation={this.state.selectedLocation}
              donations={this.DONATIONS}
              selectedDonation={this.state.selectedDonation}
              changeState={this.changeState}
            />
            <div className="checkout-separator" />
            {!this.state.userVerified ? (
              <UserVerificationForm
                data-test="user-verification-form"
                email={this.state.email}
                errorEmail={this.state.errorEmail}
                errorCode={this.state.errorCode}
                changeState={this.changeState}
              />
            ) : (
              <React.Fragment>
                <UserForm
                  data-test="user-form"
                  state={this.state}
                  changeState={this.changeState}
                />
                <div className="checkout-separator" />
                {this.renderStripeElement()}
              </React.Fragment>
            )}
          </div>
          {/*<div
            className="center"
            style={{
              width: "100%",
              height: 100,
              textAlign: "center",
              background: "#f04",
              color: "white",
            }}
          >
            <h4 className="raleway">
              This page is momentarily unavailable.
              <br /> You will be able to send your generous donation later
              Today.
            </h4>
          </div>*/}
        </div>
        <StatusPage
          data-test="status-page"
          showStatusPage={this.state.showStatusPage}
          paymentStatus={this.state.paymentStatus}
          closeModal={this.close}
          paymentStatusObj={this.PAYMENT_STATUS}
          paymentReference={this.state.paymentReference}
          additionalMessage={this.state.additionalMessage}
        />
      </ModalSlide>
    );
  }
}

export default withStyles(styles, globalStyle)(Checkout);
