import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../global-styles/style.css";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    const ref = this;
    setTimeout(function() {
      ref.modalWrapper.style.opacity = 1;
    }, 50);
  }

  closeModal(e) {
    const { closeModalOnClick } = this.props;
    const ref = this;
    if (closeModalOnClick && !this.modalContent.contains(e.target)) {
      this.modalWrapper.style.opacity = 0;
      setTimeout(function() {
        ref.props.closeModal();
      }, 200);
    }
  }

  render() {
    const { children, minWidth, maxWidth, width, maxHeight } = this.props;
    return (
      <div
        ref={node => (this.modalWrapper = node)}
        className="modal-component-wrapper"
        onClick={this.closeModal}
      >
        <div
          ref={node => (this.modalContent = node)}
          className="modal-component-content center"
          style={{
            minWidth: minWidth,
            maxWidth: maxWidth,
            width: width,
            maxHeight: maxHeight
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  minWidth: 300,
  width: 400,
  maxWidth: "80%",
  maxHeight: "90%",
  closeModalOnClick: true
};

export default withStyles(styles, globalStyle)(Modal);
