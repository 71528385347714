import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

const Item = props => {
  return (
    <div className="ap-belief-item flex align-center justify-center">
      <h3>{props.number}</h3>
      <h4 className="raleway-typography">{props.text}</h4>
    </div>
  );
};

const Belief = () => {
  return (
    <section className="ap-belief-wrapper center column">
      <div className="ap-belief-header center">
        <h1 className="raleway-typography">Our Belief & Vision </h1>
      </div>
      <div className="ap-belief-content flex align-center justify-around">
        <Item
          text="Make the good news known throughout the world."
          number="1"
        />
        <Item text="To bring relief to those who are afflicted." number="2" />
        <Item
          text="To deliver all who are under the control of the devil."
          number="3"
        />
        <Item
          text="To further consolidate the ministry of the gospel."
          number="4"
        />
        <Item
          text="Demonstrate with power and authority what Jesus can do for everyone."
          number="5"
        />
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Belief);
