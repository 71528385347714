import { insert } from "./utils";

export const formatStringAmount = async (e, caretPos) => {
  let char = e.target.value;
  char = String(char).replace(/ /g, "");
  let _char = await format(char);
  let pos = "";
  let _caretPos = caretPos;
  if (_char.length > 4) {
    let a = _char.length - 4;
    _caretPos = caretPos + a;
    if (_char.length > 5) _caretPos = caretPos + a - 1;
    if (_char.length > 7) _caretPos = caretPos + a - 2;
  }
  let charsAfterCaret = _char.length - _caretPos;
  pos = _char.length - charsAfterCaret;
  return [_char, pos, false, ""];
};

const format = (char) => {
  let normalizedChar = String(char).replace(".", "").replace(",", "");
  let a = currencyFormat(normalizedChar);
  return a;
};

function currencyFormat(char) {
  if (String(char).length === 2) {
    return "0." + char;
  }

  if (String(char).length === 1) {
    return "0.0" + char;
  }

  if (String(char).length > 2 && String(char).length < 6) {
    let pos = char.length - 2;
    return _insert(char, pos, ".");
  }

  if (String(char).length > 5) {
    let commaPos = char.length - 5;
    let dotPos = char.length - 2;
    let dottedSeparatedChar = _insert(char, dotPos, ".");
    return _insert(dottedSeparatedChar, commaPos, ",");
  }
  return char;
}

function _insert(char, idx, str) {
  let formattedChar = insert(char, idx, str);
  formattedChar = formattedChar.split(".");
  let decimals = formattedChar[0];
  let cents = formattedChar[1];
  let allZeros = true;
  for (let i = 0; i < decimals.length; i++) {
    if (decimals.charAt(i) !== "0") allZeros = false;
  }
  if (allZeros) decimals = "0";
  if (decimals.length > 1 && String(decimals).startsWith("0"))
    decimals = decimals.slice(1);
  return decimals + "." + cents;
}
