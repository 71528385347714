import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import close_svg from "../../../../../resources/svg/close-btn.svg";
import { api } from "../../../../../api";

const Header = (props) => {
  return (
    <section className="cop-header center column">
      {!api.utils.isMobile() ? (
        <div className="cop-header-close-modal-btn">
          <img onClick={props.closeModal} src={close_svg} alt="close" />
        </div>
      ) : null}
      <h1>LVD CHURCH | UK</h1>
      <h4>ONLINE GIVING</h4>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Header);
