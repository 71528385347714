import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const ScrollIntoView = ({ children, location, history }) => {
  // const prevLocation = useRef();
  useEffect(() => {
    if (history.action === "PUSH") {
      window.scrollTo(0, 0);
    }
    /* ******************************* */
    /* LEAVING THIS CODE FOR REFERENCE */
    /* ******************************* */
    // if (prevLocation.current !== location.pathname) {
    //   window.scrollTo(0, 0);
    //   prevLocation.current = location.pathname;
    // }
  });

  return children;
};

ScrollIntoView.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export default withRouter(ScrollIntoView);
