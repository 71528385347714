import { timestampConverter } from "./timestampConverter";
import {
  validateTextInput,
  validateTextInputByComparison
} from "./validateTextInput";
import { isMobile } from "./isMobile";

export const utils = {
  timestampConverter,
  validateTextInput,
  validateTextInputByComparison,
  isMobile
};
