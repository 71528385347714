import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./common.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import Loader from "react-loader-spinner";

export const LoadingCard = () => {
  return (
    <div data-test="wrapper" className="cop-status-card-content center column">
      <Loader
        data-test="loader"
        type="Oval"
        color="#008BF7"
        height={100}
        width={100}
        timeout={0} // run forever
      />
      <h3 data-test="title" className="raleway-typography">
        PROCESSING DONATION
      </h3>
      <p data-test="description" className="raleway-typography">
        Please wait while we process your transaction...
      </p>
    </div>
  );
};

export default withStyles(styles, globalStyle)(LoadingCard);
