import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./user-card-style.css";
import globalStyle from "../../../../components/global-styles/style.css";

export const UserCard = (props) => {
  const {
    name,
    email,
    address,
    address_line_2,
    city,
    postcode,
    country,
  } = props.state;
  return (
    <div data-test="wrapper" className="user-billing-info__wrapper center">
      <div data-test="content" className="user-billing-info__content center">
        <div
          data-test="user-detail-wrapper"
          className="user-billing-info__text-wrapper flex align-start justify-center column"
        >
          <h3 data-test="name" className="raleway-typography">
            {name}
          </h3>
          <h4 data-test="email" className="raleway-typography">
            {email}
          </h4>
          <h5 data-test="address" className="raleway-typography">
            {address},
          </h5>
          <h5 data-test="address_line_2" className="raleway-typography">
            {address_line_2},
          </h5>
          <h5 data-test="city" className="raleway-typography">
            {city}
          </h5>
          <h5 data-test="postcode-country" className="raleway-typography">
            {postcode}, {country.name}
          </h5>
        </div>
        <div
          data-test="btn-wrapper"
          className="user-billing-info__btn-wrapper center"
        >
          <button
            data-test="btn"
            onClick={() => props.setUserFound("userFound", false)}
          >
            Modify
          </button>
        </div>
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  name: "",
  email: "",
  address_line1: "",
  address_line2: "",
  city: "",
  postcode: "",
  country: "",
};

export default withStyles(styles, globalStyle)(UserCard);
