import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";

const Description = () => {
  return (
    <React.Fragment>
      <section
        className="cp-sisterhood-description-wrapper center column"
        style={{ padding: "50px" }}
      >
        <h2 className="shrikhand-typography" style={{ color: "#0D803F" }}>
          'YOU SHALL BE A CROWN OF BEAUTY IN THE HAND OF THE LORD, AND A ROYAL
          DIADEM IN THE HAND OF YOUR GOD.'
          <br />
          Isaiah 62:3
        </h2>
      </section>
      <section className="cp-sisterhood-description-wrapper center column">
        <h2 className="shrikhand-typography">BE PART OF THE MOVEMENT</h2>
        <p className="raleway-typography">
          Sisterhood are every day women in our church, finding their place and
          purpose, responding to a simple invitation to Be The Change and make a
          positive difference in their local communities. We gather weekly and
          we’d love you to join us in the sisterhood movement.
        </p>
      </section>
      <section className="cp-sisterhood-join-wrapper center column">
        <h2>Want to join?</h2>
        <p className="raleway-typography">
          Join us at one of our locations this Sunday and connect with our team
          to learn more or get in touch online.
        </p>
        <div className="cp-sisterhood-join-btn-wrapper flex align-center justify-around ">
          <a className="cp-sisterhood-join-btn center" href="/#locations">
            LOCATIONS
          </a>
          <a
            className="cp-sisterhood-join-btn center"
            href={`mailto:lvdstaff@lvdchurchuk.org`}
          >
            GET IN TOUCH
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Description));
