import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../components/parallax-container";
import hero_img from "../../../../../resources/images/hero/prayer-hero.png";

class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        parallaxContainerHeight="60vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.2)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.2)"
        minHeight={500}
      >
        <div
          className="prayerpage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h1 className="prayerpage-hero-section-headline white">PRAYER</h1>
          <h2 className="prayerpage-hero-section-subheadline white">
            What do you want us to pray for?
          </h2>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
