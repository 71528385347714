import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import downArrow_svg from "../../../../../resources/svg/down-arrow.svg";
import Currencies from "../../currency.json";
import { api } from "../../../../../api";
import InfoBox from "../infobox";

export class AmountInput extends React.Component {
  constructor(props) {
    super(props);
    this.DROPDOWN_STATE = {
      OPEN: "show-currency-dropdown",
      CLOSE: "",
    };

    this.state = {
      caretPos: 4,
      currencyDropdownToggleClass: this.DROPDOWN_STATE.CLOSE,
    };
    this.infoboxRef = null;
    this.dropdownButton = React.createRef();
    this.dropdownRef = React.createRef();
    this.nameInput = React.createRef();
  }

  componentDidMount = () => {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var inputEl = document.getElementById("amount-input");
    var isFocused = document.activeElement === inputEl;
    if (!iOS && isFocused) this.nameInput.focus();
    window.addEventListener("keyup", this.getCaretPosition);
    window.addEventListener("click", this.getCaretPosition);
    document.addEventListener("mousedown", this.closeDropdown, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.getCaretPosition);
    window.removeEventListener("click", this.getCaretPosition);
    document.removeEventListener("mousedown", this.closeDropdown, false);
  };

  onChange = async (e) => {
    const { caretPos } = this.state;
    if (!this.isNumber(e.target.value)) return null;
    const values = await api.currency.formatStringAmount(e, caretPos);
    this.props.changeState("amount", values[0]);
    this.setState({ caretPos: values[1] });
    this.setCaretPosition(values[1]);
    this.props.changeState("amountValueError", values[2]);
    this.props.changeState("errorMessage", values[3]);
    return values;
  };
  getCaretPosition = async (e) => {
    let caretPos = e.target.selectionStart;
    await this.setState({ caretPos });
  };
  setCaretPosition = (caretPos) => {
    var elem = document.getElementById("amount-input");
    if (elem != null) {
      if (elem.createTextRange) {
        var range = elem.createTextRange();
        range.move("character", caretPos);
        range.select();
      } else {
        if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else elem.focus();
      }
    }
  };

  isNumber = (str) => {
    return /^[0-9,.]*$/.test(str);
  };

  select = (currency) => {
    this.props.changeState("selectedCurrency", currency);
    this.props.changeState("currencyCode", currency.code);
    this.toggleCurrencyDropdown("");
  };
  closeDropdown = (e) => {
    try {
      const { currencyDropdownToggleClass } = this.state;
      if (
        !this.dropdownRef.contains(e.target) &&
        !this.dropdownButton.contains(e.target) &&
        !this.infoboxRef.contains(e.target) &&
        currencyDropdownToggleClass === this.DROPDOWN_STATE.OPEN
      ) {
        this.toggleCurrencyDropdown();
      }
    } catch (exception) {
      return null;
    }
  };

  selectedCurrency = (currency) => {
    return this.props.selectedCurrency.symbol === currency.symbol;
  };

  toggleCurrencyDropdown = () => {
    const { currencyDropdownToggleClass } = this.state;
    if (currencyDropdownToggleClass === this.DROPDOWN_STATE.OPEN) {
      this.setState({ currencyDropdownToggleClass: this.DROPDOWN_STATE.CLOSE });
    } else
      this.setState({ currencyDropdownToggleClass: this.DROPDOWN_STATE.OPEN });
  };

  render() {
    return (
      <section
        data-test="wrapper"
        className="checkout-amount-wrapper center column"
      >
        <div
          data-test="label-wrapper"
          className="checkout-amount-title-wrapper flex align-center justify-start"
        >
          <h2 data-test="label-text" className="raleway-typography">
            Amount
          </h2>
        </div>
        <InfoBox
          data-test="info-box"
          inputRef={(node) => (this.infoboxRef = node)}
          text="If you’re giving outside the UK, please change your currency donation."
          action={this.toggleCurrencyDropdown}
          actionText="Change currency"
          height={70}
        />

        <div
          data-test="body-wrapper"
          className="checkout-amount-content-wrapper flex align-center justify-start"
        >
          <div
            data-test="currency-wrapper"
            className="checkout-amount-currency-wrapper"
          >
            <div
              data-test="currency-btn-wrapper"
              className="checkout-amount-currency center"
              ref={(node) => (this.dropdownButton = node)}
              onClick={this.toggleCurrencyDropdown}
            >
              <img
                data-test="currency-flag"
                src={require(`../../../../../resources/svg/flags/${this.props.selectedCurrency.flag}`)}
                alt="flag"
              />
              <h4 data-test="currency-code" className="raleway-typography">
                {this.props.selectedCurrency.symbol}
              </h4>
              <img
                data-test="currency-down-arrow"
                src={downArrow_svg}
                style={{ width: 8, height: 8 }}
                alt="arrow"
              />
            </div>
            <div
              data-test="currency-dropdown-wrapper"
              className={`currency-dropdown-content ${this.state.currencyDropdownToggleClass}`}
              ref={(node) => (this.dropdownRef = node)}
            >
              {Currencies.map((currency, index) => {
                return (
                  <div
                    data-test="currency-dropdown-row"
                    key={index}
                    className={`currency-dropdown-row flex align-center justify-around ${
                      this.selectedCurrency(currency)
                        ? "currency-dropdown-row-selected"
                        : ""
                    }`}
                    onClick={() => this.select(currency)}
                  >
                    <img
                      data-test="currency-dropdown-row-flag"
                      src={require(`../../../../../resources/svg/flags/${currency.flag}`)}
                      alt="flag"
                    />
                    <h4
                      data-test="currency-dropdown-row-code"
                      style={{
                        color: this.selectedCurrency(currency)
                          ? "white"
                          : "black",
                      }}
                    >
                      {currency.symbol}
                    </h4>
                    <p
                      data-test="currency-dropdown-row-description"
                      style={{
                        color: this.selectedCurrency(currency)
                          ? "#A8AAAC"
                          : "#708089",
                      }}
                    >
                      {currency.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            data-test="input-wrapper"
            className="checkout-amount-input-wrapper center"
          >
            <input
              data-test="input"
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              id="amount-input"
              name="amount"
              className="checkout-amount-input"
              placeholder="0.00"
              ref={(input) => {
                this.nameInput = input;
              }}
              onClick={this.getCaretPosition}
              value={this.props.amount}
              onChange={this.onChange}
              maxLength={9}
              onKeyDown={(e) =>
                (e.key === "," || e.key === ".") && e.preventDefault()
              }
            />
          </div>
        </div>
      </section>
    );
  }
}

export default withStyles(styles, globalStyle)(AmountInput);
