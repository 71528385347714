import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";
// import { HashLink as Link } from "react-router-hash-link";

const Description = () => {
  return (
    <React.Fragment>
      <section className="cp-groups-description-wrapper center column">
        <h2>Live your best life with a genuine community</h2>
        <p className="raleway-typography">
          Our weekly group provide a space for you to discover God's best plan
          for your life! Through a group, you can expect to develop incredible
          relationships with a genuine community while growing in your walk with
          God. We have groups gathering in Crawley as well as in the London area
          and would love to see you get connected to one! So what're you waiting
          for? Find a community near you and get ready to do life together.
        </p>
      </section>
      <section className="cp-groups-join-wrapper center column">
        <h2>Interested in joining a group?</h2>
        <p className="raleway-typography">
          Join us at one of our locations this Sunday and connect with our team
          to learn more or get in touch online.
        </p>
        <div className="cp-groups-join-btn-wrapper flex align-center justify-around ">
          <a className="cp-groups-join-btn center" href="/#locations">
            LOCATIONS
          </a>
          <a
            className="cp-groups-join-btn center"
            href={`mailto:lvdstaff@lvdchurchuk.org`}
          >
            GET IN TOUCH
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Description));
