import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import { CardElement } from "@stripe/react-stripe-js";

export const StripeCardElement = (props) => {
  return (
    <div
      data-test="wrapper"
      className="checkout-payment-form-credit-card-wrapper center column"
    >
      <h2 data-test="header">Card details</h2>
      <CardElement
        data-test="card"
        onReady={props.setCardElement}
        onChange={props.onChangeStripeCard}
        options={{
          iconStyle: "solid",
          hidePostalCode: true,
          style: {
            base: {
              iconColor: "darkgray",
              color: "#464646",
              fontSmoothing: "antialiased",
              fontSize: "20px",
              letterSpacing: "0.025em",
              "::placeholder": {
                color: "#aab7c4",
              },
              padding: "15px",
            },
            invalid: {
              iconColor: "blue",
              color: "#e5424d",
              ":focus": {
                color: "#303238",
              },
              fontWeight: "500",
            },
          },
        }}
      />
    </div>
  );
};

export default withStyles(styles, globalStyle)(StripeCardElement);
