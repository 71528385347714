import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./common.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import error_svg from "../../../../../../resources/svg/error-coloured.svg";

export const TIMEDOUT_RESPONSE = {
  TITLE: "REQUEST TIMEDOUT",
  SUBTITLE: "The request is taking too long to be processed.",
  BOLD_DESCRIPTION: "Please DO NOT try again",
  DESCRIPTION: {
    LINE1: "Send an email to ",
    LINE2: "itsupport@lvdchurchuk.org",
    LINE3: `including this reference number: "`,
    LINE4: `" and one of our team will be in contact shortly to confirm the status
    of your payment.`,
  },
};

export const TimedoutCard = (props) => {
  return (
    <div
      data-test="wrapper"
      className="cop-status-card-content center column"
      style={{ height: 400 }}
    >
      <img data-test="img" src={error_svg} alt="response_status" />
      <h3 data-test="title" className="raleway-typography">
        {TIMEDOUT_RESPONSE.TITLE}
      </h3>
      <h4 data-test="subtitle" className="raleway-typography">
        {TIMEDOUT_RESPONSE.SUBTITLE}
      </h4>
      <h4 data-test="bold-description" style={{ color: "red" }}>
        {TIMEDOUT_RESPONSE.BOLD_DESCRIPTION}
      </h4>
      <p data-test="description">
        {TIMEDOUT_RESPONSE.DESCRIPTION.LINE1}
        <span style={{ fontWeight: "bold" }}>
          {TIMEDOUT_RESPONSE.DESCRIPTION.LINE2}
        </span>{" "}
        {TIMEDOUT_RESPONSE.DESCRIPTION.LINE3}
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
          {props.paymentReference}
        </span>
        {TIMEDOUT_RESPONSE.DESCRIPTION.LINE4}
      </p>
    </div>
  );
};

export default withStyles(styles, globalStyle)(TimedoutCard);
