import React from "react";
import styles from "./style.css";
import hillsong_bg from "../../../../../resources/images/hillsong-bg-black.jpg";

class LiveStream extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataWidth: "500px",
      dataHeight: "600px",
    };
  }

  componentDidMount = () => {
    const script = document.createElement("script");
    script.src =
      "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v6.0";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  };

  render() {
    const { href, cite } = this.props.liveStream;
    try {
      return (
        <section
          className="live-stream-wrapper"
          style={{
            backgroundImage: "url(" + hillsong_bg + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="live-stream-header">
            <h1 className="white">WELCOME HOME</h1>
            <h4 className="white">LA VOIX DE LA DELIVRANCE | UK</h4>
            <h3 className="white">WE ARE LIVE</h3>
          </div>
          <div className="facebook-wrapper center">
            <div id="fb-root">
              <div
                className="fb-video"
                data-href={href}
                data-show-text="false"
                data-width={this.state.dataWidth}
                data-height={this.state.dataHeight}
              >
                <blockquote
                  cite={cite}
                  className="fb-xfbml-parse-ignore"
                ></blockquote>
              </div>
            </div>
          </div>
        </section>
      );
    } catch (exception) {
      this.props.blockLiveStream();
    }
  }
}

export default LiveStream;
