import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import globalStyle from "../../../../components/global-styles/style.css";
import styles from "./style.css";
import Item from "./components/item";

const Communities = ({ lang, baseUrl }) => {
  return (
    <section className="hp-communities-wrapper center column">
      <div className="hp-communities-header center column">
        <h1 className="raleway-typography">{lang.title}</h1>
        <p>{lang.paragraph}</p>
      </div>
      <div className="hp-communities-content flex align-start justify-around">
        <Item
          imgFrameColor="#9FD1EA"
          title={lang.item1.title}
          description={lang.item1.description}
          btnText={lang.item1.btnText}
          link={`${baseUrl}groups`}
        />
        <Item
          imgFrameColor="pink"
          backgroundPositionY={0}
          title={lang.item2.title}
          description={lang.item2.description}
          btnText={lang.item2.btnText}
          link={`${baseUrl}sisterhood`}
        />
        <Item
          imgFrameColor="#DFA748"
          title={lang.item3.title}
          description={lang.item3.description}
          btnText={lang.item3.btnText}
          link={`${baseUrl}youth`}
          marginBottom={false}
        />
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Communities);
