import React, { useState, useReducer } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import Form from "./component/form";
import { api } from "../../../api";
import ConfirmationModal from "../../components/confirmation-modal";
import Loader from "react-loader-spinner";

const responseObj = {
  success: {
    title: "Thank You!",
    message: `Your Gift Aid Form has been sent. 
    As a church we appreciate your support 
    as this will make a great impact in the 
    life of our church, enabling us to reach 
    more people and spread the Good News of 
    Jesus Christ.`,
  },
  error: {
    title: "Sorry...",
    message: `There was a problem sending your Gift Aid form.
      Please try again.`,
  },
  empty: {
    title: "",
    message: "",
  },
};

const GiftAid = () => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(responseObj.empty);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      surname: "",
      addr1: "",
      addr2: "",
      city: "",
      region: "",
      postcode: "",
      email: "",
      phone: "",
      consent: false,
      displayErrorName: false,
      displayErrorSurname: false,
      displayErrorAddr1: false,
      displayErrorAddr2: false,
      displayErrorCity: false,
      displayErrorRegion: false,
      displayErrorPostcode: false,
      displayErrorEmail: false,
      displayErrorPhone: false,
      displayErrorConsent: false,
      loading: false,
    }
  );

  function onChange(e) {
    const { name, value } = e.target;
    setState({ [name]: value });
    let target = firstLetterUppercase(name);
    changeState(`displayError${target}`, false);
  }

  function firstLetterUppercase(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  function onConsentChange() {
    setState({ consent: !state.consent, displayErrorConsent: false });
  }

  async function submitForm() {
    try {
      let allFieldsValidated = await validateFields();
      if (allFieldsValidated && !loading) {
        setLoading(true);
        const mailObj = {
          from: `GIFT AID <${state.email}>`,
          to: `<itsupport@lvdchurchuk.org>`,
          subject: `${state.name} ${state.surname}'s Gift Aid Form`,
          template: "gift_aid_user_data",
          "v:sender_name": `${state.name}`,
          "v:sender_surname": `${state.surname}`,
          "v:sender_addr1": `${state.addr1},`,
          "v:sender_addr2": `${state.addr2},`,
          "v:sender_city": `${state.city},`,
          "v:sender_region": `${state.region},`,
          "v:sender_postcode": `${state.postcode}`,
          "v:sender_email": `${state.email}`,
          "v:sender_phone": `${state.phone}`,
          "v:sender_digital_signature": `${state.consent}`,
        };
        const mailResponse = await api.mail.send(mailObj);
        setResponseStates(mailResponse);
        resetForm();
      }
    } catch (exception) {
      console.log(exception);
      const mailResponse = { code: 503 };
      setResponseStates(mailResponse);
      resetForm();
    }
  }

  async function validateFields() {
    const validateName = api.utils.validateTextInput(state.name);
    const validateSurname = api.utils.validateTextInput(state.surname);
    const validateAddr1 = api.utils.validateTextInput(state.addr1);
    const validateAddr2 = api.utils.validateTextInput(state.addr2);
    const validateCity = api.utils.validateTextInput(state.city);
    const validateRegion = api.utils.validateTextInput(state.region);
    const validatePostcode = api.utils.validateTextInput(state.postcode);
    const validateEmail = api.utils.validateTextInput(state.email);
    const validatePhone = api.utils.validateTextInput(state.phone);
    if (!validateName) await changeState("displayErrorName", true);
    if (!validateSurname) await changeState("displayErrorSurname", true);
    if (!validateAddr1) await changeState("displayErrorAddr1", true);
    if (!validateAddr2) await changeState("displayErrorAddr2", true);
    if (!validateCity) await changeState("displayErrorCity", true);
    if (!validateRegion) await changeState("displayErrorRegion", true);
    if (!validatePostcode) await changeState("displayErrorPostcode", true);
    if (!validateEmail) await changeState("displayErrorEmail", true);
    if (!validatePhone) await changeState("displayErrorPhone", true);
    if (!state.consent) await changeState("displayErrorConsent", true);
    if (
      validateName &&
      validateSurname &&
      validateAddr1 &&
      validateAddr2 &&
      validateCity &&
      validateRegion &&
      validatePostcode &&
      validateEmail &&
      validatePhone &&
      state.consent
    ) {
      return true;
    }
    return false;
  }

  function changeState(input, value) {
    setState({ [input]: value });
  }

  function setResponseStates(res) {
    if (res.code === 200) {
      setResponse(responseObj.success);
    } else setResponse(responseObj.error);
    setOpenModal(true);
  }

  function resetForm() {
    setLoading(false);
    changeState("name", "");
    changeState("surname", "");
    changeState("addr1", "");
    changeState("addr2", "");
    changeState("city", "");
    changeState("region", "");
    changeState("postcode", "");
    changeState("email", "");
    changeState("phone", "");
    changeState("consent", false);
    changeState("displayErrorName", false);
    changeState("displayErrorSurname", false);
    changeState("displayErrorAddr1", false);
    changeState("displayErrorAddr2", false);
    changeState("displayErrorCity", false);
    changeState("displayErrorRegion", false);
    changeState("displayErrorPostcode", false);
    changeState("displayErrorEmail", false);
    changeState("displayErrorPhone", false);
    changeState("displayErrorConsent", false);
  }

  return (
    <section className="gift-aid-section-wrapper center column">
      {openModal ? (
        <ConfirmationModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          title={response.title}
          message={response.message}
        />
      ) : null}
      <div className="gift-aid-section-content flex align-center justify-start column">
        <h2>
          BOOST YOUR GIVING BY 25P FOR EVERY £1 YOU DONATE THROUGH GIFT AID
        </h2>
        <h1>GIFT AID</h1>
        <p>
          If you are a UK Taxpayer you can add Gift Aid to your giving. Gift Aid
          is a UK government initiative, where the basic rate of tax you have
          already paid on your giving is relinquished to the charitable
          organisation you are giving to. Donating through Gift Aid means we can
          claim an extra 25p for every £1 you give.
        </p>
        <h4>It won’t cost you any extra.</h4> <br /> <br />
        <p>
          Gift Aid allows us to reclaim basic tax paid on donations made by UK
          taxpayers. This means that if you give £100.00 and make a Gift Aid
          Declaration, your overall contribution will now be worth £125.00.
        </p>
        <br />
        <p>
          If you would like assistance with regards registering for Gift Aid
          please email admin@lvdchurchuk.org
        </p>
        <Form
          name={state.name}
          surname={state.surname}
          addr1={state.addr1}
          addr2={state.addr2}
          city={state.city}
          region={state.region}
          postcode={state.postcode}
          email={state.email}
          phone={state.phone}
          consent={state.consent}
          onChange={onChange}
          onConsentChange={onConsentChange}
          displayErrorName={state.displayErrorName}
          displayErrorSurname={state.displayErrorSurname}
          displayErrorAddr1={state.displayErrorAddr1}
          displayErrorAddr2={state.displayErrorAddr2}
          displayErrorCity={state.displayErrorCity}
          displayErrorRegion={state.displayErrorRegion}
          displayErrorPostcode={state.displayErrorPostcode}
          displayErrorEmail={state.displayErrorEmail}
          displayErrorPhone={state.displayErrorPhone}
          displayErrorConsent={state.displayErrorConsent}
        />
        {loading ? (
          <Loader
            type="Watch"
            color="gray"
            height={50}
            width={50}
            timeout={0} //3 secs
          />
        ) : (
          <button className="center" onClick={() => submitForm()}>
            SUBMIT
          </button>
        )}
      </div>
    </section>
  );
};

export default {
  component: withStyles(styles, globalStyle)(GiftAid),
};
