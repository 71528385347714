import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import Hero from "./components/hero/Hero";
import WelcomeSection from "./components/welcome";
import LocationsSection from "./components/locations";
import Communities from "./components/communitites";
import Giving from "./components/giving";
import LiveStream from "./components/live-streams";
import firebase from "firebase";
import "firebase/firestore";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveStream: { display: false },
    };
  }

  componentDidMount = () => {
    document.title = "LVD Church | UK";
    this.fetchLiveStream();
  };

  fetchLiveStream = () => {
    const db = firebase.firestore();
    let path = "live-stream/facebook";
    db.doc(path)
      .get()
      .then((doc) => {
        this.setState({ liveStream: doc.data() });
      });
  };

  blockLiveStream = () => {
    this.setState({ liveStream: { display: false } });
  };

  render() {
    const { props } = this;
    const { liveStream } = this.state;
    return (
      <div className="homepage_container">
        {liveStream.display ? (
          <LiveStream
            liveStream={liveStream}
            blockLiveStream={this.blockLiveStream}
          />
        ) : (
          <Hero lang={props.hero} />
        )}

        <WelcomeSection lang={props.welcome} />
        <LocationsSection lang={props.location} />
        <Communities lang={props.communities} baseUrl={props.baseUrl} />
        <Giving lang={props.giving} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hero: state.language.obj.home.hero,
    welcome: state.language.obj.home.welcome,
    location: state.language.obj.home.locations,
    communities: state.language.obj.home.communities,
    giving: state.language.obj.home.giving,
    baseUrl: state.baseUrl,
  };
}

export default {
  component: connect(mapStateToProps, {})(withStyles(styles)(Home)),
};
