import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

const Story = () => {
  return (
    <section className="aboutpage-story-wrapper">
      <div className="ap-story-content center">
        <p className="raleway-typography">
          Inspired by the Holy Spirit of God, the Church The Voice of
          Deliverance (LVD) was created in 1983 by Pastor Cyril Demba. Chosen to
          carry the gospel in an effective way to demonstrate the power of God,
          this church, formed with the help of other ministers of the gospel, is
          now firmly anchored in Mauritius. The Voice of Deliverance is present
          in many countries around the world, and is a testament to the good
          work that has been done over the last thirty years.
        </p>
      </div>
      <div className="ap-bible-verse-content center column">
        <h2 className="oswald-typography">
          IF YOU ABIDE IN MY WORD, YOU ARE MY DISCEPLED INDEED. AND YOU SHALL
          KNOW THE TRUTH, AND THE TRUTH SHALL MAKE YOU FREE.
        </h2>
        <p className="oswald-typography">- JOHN 8:31-32 -</p>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Story);
