import firebase from "firebase/app";
import "firebase/firestore";

export const FETCH_EVENTS = "fetch_events";

export const fetchEvents = () => async (dispatch, getState) => {
  const db = firebase.firestore();
  const EVENTS = [];
  return db
    .collection("events")
    .get()
    .then(async (events) => {
      for (let evt of events.docs) {
        const event = evt.data();
        let previousEvent = await checkIfPreviousEvent(event.date);
        if (previousEvent) {
          continue;
        }
        EVENTS.push(event);
      }
      await EVENTS.sort(orderEvents);
      return dispatch({
        type: FETCH_EVENTS,
        payload: EVENTS,
      });
    })
    .catch((err) => {
      dispatch({
        type: FETCH_EVENTS,
        payload: [],
      });
      console.log(err);
    });
};

function checkIfPreviousEvent(eventTimestamp) {
  let currentDate = new Date();
  let eventDate = new Date(eventTimestamp.toDate());
  currentDate.setHours(0, 0, 0, 0);
  if (eventDate < currentDate) {
    return true;
  }
  return false;
}

function orderEvents(a, b) {
  if (a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
}
