import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import children_madagascar from "../../../../../resources/images/mission/children-of-madagascar.jpg";
import children_madagascar_2 from "../../../../../resources/images/mission/children-of-madagascar-2.jpg";

export const sections = [
  {
    image: children_madagascar,
    title: "HEART",
    description: `As a church, we have embraced opportunities to see the Gospel of Jesus outworked practically. 
      Our Heart is to deliver hope and help meet the needs of communities all around the world and when we combine 
      our strength and resource to their expertise and influence, we can have a greater impact. There is power in collective 
      and intentional action when people are united in love and a just cause.`,
    reverse: false,
  },
  {
    image: children_madagascar_2,
    title: "PROJECT",
    description: `Children are often the greatest victims of poverty and live in desperate conditions. But it is these children who 
    hold the potential to break the cycle of deprivation for future generations. Our church in response to that has opened an Orphanage 
    in Madagascar. Across LVD Church UK and through our Mission Outreach we have been able to sponsor 18 children in this region of the world.
    By helping us sponsoring children, we believe we can see the nation of Madagascar changed.`,
    reverse: true,
  },
];

const Item = ({ image, title, description, reverse }) => {
  if (reverse) {
    return (
      <section
        data-test="mission-description-item-section"
        className="mission-project-section-wrapper center column"
      >
        <div
          className="mission-project-section-content flex align-center justify-around"
          style={{ flexWrap: "wrap-reverse " }}
        >
          <div className="mission-project-section-right-wrapper flex align-start justify-center column">
            <h2
              data-test="mission-description-item-title"
              className="raleway-typography"
            >
              {title}
            </h2>
            <p
              data-test="mission-description-item-description"
              className="raleway-typography"
            >
              {description}
            </p>
          </div>
          <div
            className="mission-project-section-left-wrapper column"
            style={{ alignItems: "flex-end" }}
          >
            <img
              data-test="mission-description-item-img"
              src={image}
              alt="madagascar-project"
            />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section
      data-test="mission-description-item-section"
      className="mission-project-section-wrapper center column"
    >
      <div
        className="mission-project-section-content flex align-center justify-around"
        style={{ flexWrap: "wrap" }}
      >
        <div className="mission-project-section-left-wrapper flex align-center justify-start column">
          <img
            data-test="mission-description-item-img"
            src={image}
            alt="madagascar-project"
          />
        </div>

        <div className="mission-project-section-right-wrapper flex align-start justify-center column">
          <h2
            data-test="mission-description-item-title"
            className="raleway-typography"
          >
            {title}
          </h2>
          <p
            data-test="mission-description-item-description"
            className="raleway-typography"
          >
            {description}
          </p>
        </div>
      </div>
    </section>
  );
};

Item.defaultProps = {
  reverse: false,
};

export const MissionDescription = () => {
  return (
    <section data-test="mission-description-wrapper">
      {sections.map((section, idx) => (
        <Item
          data-test="mission-description-item"
          key={idx}
          image={section.image}
          title={section.title}
          description={section.description}
          reverse={section.reverse}
        />
      ))}
    </section>
  );
};

export default withStyles(styles, globalStyle)(MissionDescription);
