import React, { useRef, useState, useEffect } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import downArrow_svg from "../../../resources/svg/down-arrow.svg";

export const Dropdown = (props) => {
  let dropdownButton = useRef(null);
  let dropdownRef = useRef(null);
  const [dropdownToggleClass, setDropdownToggleClass] = useState("");

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown, false);
    return () => {
      document.removeEventListener("mousedown", closeDropdown, false);
    };
  });

  function toggleDropdown() {
    setDropdownToggleClass(dropdownToggleClass === "show" ? "" : "show");
  }

  function closeDropdown(e) {
    if (
      !dropdownRef.contains(e.target) &&
      !dropdownButton.contains(e.target) &&
      dropdownToggleClass === "show"
    ) {
      toggleDropdown();
    }
  }

  function select(option) {
    props.setOption(option);
    toggleDropdown();
  }

  function selectedRow(option) {
    return props.optionSelected === option;
  }

  return (
    <div className="dropdown center" data-test="wrapper">
      <h5 data-test="label">{props.dropdownTitle}</h5>
      <button
        onClick={toggleDropdown}
        className="dropbtn center"
        ref={(node) => (dropdownButton = node)}
        data-test="button"
      >
        <div
          className="dropdown-selected-option-wrapper flex align-center justify-start"
          data-test="selected-option-wrapper"
        >
          <h4 data-test="selected-option-text">{props.optionSelected}</h4>
        </div>
        <div
          className="dropdown-icon-wrapper center"
          data-test="selected-option-svg-wrapper"
        >
          <img
            src={downArrow_svg}
            alt="down-arrow"
            style={{ width: 10, height: 10 }}
            data-test="selected-option-svg"
          />
        </div>
      </button>
      <div
        id="myDropdown"
        className={`dropdown-content ${dropdownToggleClass}`}
        ref={(node) => (dropdownRef = node)}
        data-test="dropdown-wrapper"
      >
        {props.options.map((option, index) => {
          return (
            <p
              data-test="dropdown-option-text"
              className={`${
                selectedRow(option) ? "dropdown-selected-option" : ""
              }`}
              key={index}
              onClick={() => select(option)}
            >
              {option}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles, globalStyle)(Dropdown);
