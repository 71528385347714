import React, { useState, useEffect } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import LoadingCard from "./components/loading-card";
import ResponseCard from "./components/response-card";
import TimeoutCard from "./components/timedout-card";
import close_svg from "../../../../../resources/svg/close-btn.svg";

const CARD_ANIMATION_STATE = {
  OPEN: {
    TRANSFORM: "translateY(0%)",
    DISPLAY: "flex",
  },
  CLOSE: {
    TRANSFORM: "translateY(100%)",
    DISPLAY: "none",
  },
};

export const StatusPage = (props) => {
  const [display, setDisplay] = useState(CARD_ANIMATION_STATE.CLOSE.DISPLAY);
  const [transform, setTransform] = useState(
    CARD_ANIMATION_STATE.CLOSE.TRANSFORM
  );

  useEffect(() => {
    if (props.showStatusPage) {
      setDisplay(CARD_ANIMATION_STATE.OPEN.DISPLAY);
      setTimeout(() => {
        setTransform(CARD_ANIMATION_STATE.OPEN.TRANSFORM);
      }, 50);
    }
  }, [props.showStatusPage]);

  function renderCard() {
    const { paymentStatusObj, paymentStatus, additionalMessage } = props;
    const { LOADING, SUCCESS, DECLINED, ERROR, TIMEDOUT } = paymentStatusObj;
    switch (paymentStatus) {
      case LOADING:
        return <LoadingCard data-test="loading-card" />;
      case SUCCESS:
        return (
          <React.Fragment>
            <div className="checkout-loading-page-close-btn">
              <img
                data-test="close-btn-card"
                onClick={props.closeModal}
                src={close_svg}
                alt="close"
              />
            </div>
            <ResponseCard
              data-test="success-response-card"
              paymentStatus={props.paymentStatus}
              paymentReference={props.paymentReference}
              paymentStatusObj={paymentStatusObj}
              additionalMessage={additionalMessage}
            />
          </React.Fragment>
        );
      case DECLINED:
        return (
          <React.Fragment>
            <div className="checkout-loading-page-close-btn">
              <img onClick={props.closeModal} src={close_svg} alt="close" />
            </div>
            <ResponseCard
              data-test="declined-response-card"
              paymentStatus={props.paymentStatus}
              paymentReference={props.paymentReference}
              paymentStatusObj={paymentStatusObj}
              additionalMessage={additionalMessage}
            />
          </React.Fragment>
        );
      case ERROR:
        return (
          <React.Fragment>
            <div className="checkout-loading-page-close-btn">
              <img onClick={props.closeModal} src={close_svg} alt="close" />
            </div>
            <ResponseCard
              data-test="error-response-card"
              paymentStatus={props.paymentStatus}
              paymentReference={props.paymentReference}
              paymentStatusObj={paymentStatusObj}
              additionalMessage={additionalMessage}
            />
          </React.Fragment>
        );
      case TIMEDOUT:
        return (
          <React.Fragment>
            <div className="checkout-loading-page-close-btn">
              <img onClick={props.closeModal} src={close_svg} alt="close" />
            </div>
            <TimeoutCard
              data-test="timedout-response-card"
              paymentStatus={props.paymentStatus}
              reference={props.reference}
              paymentReference={props.paymentReference}
              paymentStatusObj={paymentStatusObj}
              additionalMessage={additionalMessage}
            />
          </React.Fragment>
        );
      default:
        return <LoadingCard />;
    }
  }

  return (
    <div
      data-test="wrapper"
      className="checkout-loading-page center"
      style={{ transform, display }}
    >
      {renderCard()}
    </div>
  );
};

export default withStyles(styles, globalStyle)(StatusPage);
