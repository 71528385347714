// import axios from "axios";

export const FETCH_USERS = "fetch_users";

export const fetchUser = () => async (dispatch, getState) => {
  // const res = await axios.get("http://react-ssr-api.herokuapp.com/users");
  const res = [
    { id: 1, name: "Luca" },
    { id: 2, name: "Marco" }
  ];
  dispatch({
    type: FETCH_USERS,
    payload: res
  });
};
