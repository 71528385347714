import React, { useState, useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import Hero from "./components/hero";
import About from "./components/about";
import PaymentOptions from "./components/payment-options";
import GiftAid from "./components/gift-aid";
import Checkout from "../Checkout";
import { api } from "../../../api";
import { displayFooter } from "../../../redux/actions/displayFooter";

const Giving = (props) => {
  document.title = "Giving | UK";
  const basePath = props.match.path;
  const [openCheckout, setOpenCheckout] = useState(
    basePath === "/check-out" ? true : false
  );

  useEffect(() => {
    var appContainer = document.getElementById("app-container-id");
    if (openCheckout) {
      if (!api.utils.isMobile()) {
        document.body.style.top = `${-window.pageYOffset}px`;
        document.body.classList.add("scroll-block");
      } else {
        props.displayFooter(false);
        appContainer.style.paddingBottom = "unset";
      }
    } else {
      if (!api.utils.isMobile()) {
        document.body.classList.remove("scroll-block");
        document.body.style.top = "unset";
      } else {
        props.displayFooter(true);
        appContainer.style.paddingBottom = "550px";
      }
    }
  }, [props, openCheckout]);

  function closeModal() {
    setOpenCheckout(!openCheckout);
    props.history.goBack();
  }

  if (api.utils.isMobile() && basePath === "/check-out") {
    return <Checkout toogleCheckout={closeModal} />;
  }

  return (
    <div id="giving-page-id">
      <Route
        path={`/check-out`}
        exact
        strict
        render={(props) => <Checkout {...props} toogleCheckout={closeModal} />}
      />
      <Hero toogleCheckout={() => setOpenCheckout(!openCheckout)} />
      <About />
      <PaymentOptions toggleCheckout={() => setOpenCheckout(!openCheckout)} />
      <GiftAid />
    </div>
  );
};

function mapStateToProps(state) {
  return { footer: state.footer };
}

export default {
  component: connect(mapStateToProps, { displayFooter })(
    withRouter(withStyles(styles)(Giving))
  ),
};
