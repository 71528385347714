import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../components/parallax-container";
import hero_img from "../../../../../resources/images/hero/giving-hero-3.png";

const Hero = (props) => {
  return (
    <ParallaxContainer
      parallaxContainerHeight="100vh"
      parallaxContainerWidth="100%"
      parallaxImgContainerHeight="150%"
      img={`url(${hero_img})`}
      backgroundPositionY={-100}
      topLinearGradientColor="rgba(0, 0, 0, 0.5)"
      bottomLinearGradientColor="rgba(0, 0, 0, 0.5)"
      minHeight={500}
    >
      <div className="givingpage-hero-container montserrat-typography">
        <h1 className="givingpage-hero-section-headline white">GENEROSITY</h1>
        <h2 className="givingpage-hero-section-subheadline white">
          See what God can do through your generosity
        </h2>
        <button
          data-test="give-online-btn"
          className="center"
          onClick={() => props.history.push("/check-out")}
        >
          GIVE ONLINE
        </button>
      </div>
    </ParallaxContainer>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Hero));
