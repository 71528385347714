import React, { useState } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import {
  CustomInputText,
  CustomTextArea,
} from "./components/custom-input-text";
import ConfirmationModal from "../../../../components/confirmation-modal";
import { api } from "../../../../../api";
import Loader from "react-loader-spinner";

const responseObj = {
  success: {
    title: "Thank You!",
    message: `Be assured that the LVD family will stand beside you in prayers. 
    It shall be done to you according to your faith.`,
  },
  error: {
    title: "Sorry...",
    message:
      "Something unexpected happened. Please refresh the page and try again.",
  },
  empty: {
    title: "",
    message: "",
  },
};

const Form = () => {
  const inputBorderColor = "rgb(189, 189, 189)";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [request, setRequest] = useState("");
  const [nameBorderColor, setNameBorderColor] = useState(inputBorderColor);
  const [emailBorderColor, setEmailBorderColor] = useState(inputBorderColor);
  const [requestBorderColor, setRequestBorderColor] = useState(
    inputBorderColor
  );
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState(responseObj.empty);
  const [responseCode, setResponseCode] = useState(200);
  const [loading, setLoading] = useState(false);

  async function submitRequest() {
    try {
      if (validateFields() && !loading) {
        setLoading(true);
        const lvdMailObj = {
          from: "Prayer Request <prayers@lvdchurchuk.org>",
          to: `<itsupport@lvdchurchuk.org>`,
          subject: "A new prayer request",
          template: "prayer_request_internal",
          "v:sender_name": name,
          "v:sender_email": `${email}`,
          "v:sender_prayer_request": `${request}`,
        };
        const userMailObj = {
          from: "LVD Prayer Request <prayers@lvdchurchuk.org>",
          to: `${email}`,
          subject: "A short message of encouragement for you",
          template: "prayer_request",
          "v:name": `${name}`,
        };
        const mail_to_lvd = await api.mail.send(lvdMailObj);
        api.mail.send(userMailObj);
        renderSubmissionUI(false, true, mail_to_lvd.code, mail_to_lvd.message);
      }
    } catch (exception) {
      renderSubmissionUI(false, true, 503, "Error");
    }
  }

  function renderSubmissionUI(_loading, _openModal, code, message) {
    setLoading(_loading);
    setResponseCode(code);
    if (code === 200) {
      setResponseMessage(responseObj.success);
    } else setResponseMessage(responseObj.error);
    setOpenModal(_openModal);
    resetForm();
  }

  function validateFields() {
    const validatedName = api.utils.validateTextInput(name);
    const validatedEmail = api.utils.validateTextInput(email);
    const validatedRequest = api.utils.validateTextInput(request);
    if (validatedName && validatedEmail && validatedRequest) {
      return true;
    }
    if (!validatedName) setNameBorderColor("#FF5252");
    if (!validatedEmail) setEmailBorderColor("#FF5252");
    if (!validatedRequest) setRequestBorderColor("#FF5252");
    return false;
  }

  function resetForm() {
    setName("");
    setEmail("");
    setRequest("");
  }

  return (
    <section className="pp-form-wrapper center">
      {openModal ? (
        <ConfirmationModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          code={responseCode}
          title={responseMessage.title}
          message={responseMessage.message}
        />
      ) : null}
      <div className="pp-form-content center column">
        <div className="pp-form-header center">
          <h1 className="raleway-typography">PRAYER REQUEST</h1>
        </div>
        <div className="pp-form-body center column">
          <CustomInputText
            label="Name / Surname"
            type="text"
            name="name"
            placeholder="Insert your name here"
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              setNameBorderColor(inputBorderColor);
            }}
            disabled={loading}
            borderColor={nameBorderColor}
          />
          <CustomInputText
            label="Email"
            type="email"
            name="email"
            placeholder="email@example.com"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
              setEmailBorderColor(inputBorderColor);
            }}
            disabled={loading}
            borderColor={emailBorderColor}
          />
          <CustomTextArea
            name="prayerRequestText"
            label="Prayer request / Praise report"
            placeholder="Type your Prayer Request here…"
            value={request}
            onChange={(event) => {
              setRequest(event.target.value);
              setRequestBorderColor(inputBorderColor);
            }}
            disabled={loading}
            borderColor={requestBorderColor}
          />
          {loading ? (
            <Loader
              type="Watch"
              color="gray"
              height={50}
              width={50}
              timeout={0} //3 secs
            />
          ) : (
            <p className="raleway-typography" onClick={() => submitRequest()}>
              SUBMIT
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Form);
