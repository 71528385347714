import React, { useState } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import InputBox from "../../../../components/input-box";
import InfoBox from "../infobox";
import { api } from "../../../../../api";
import Loader from "react-loader-spinner";

const OPACITY_ANIMATION_DURATION = 250;
export const INSTRUCTIONS = {
  INSERT_EMAIL: {
    TEXT: `Please insert your email and we'll send you a verification code.`,
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  EMAIL_EMPTY: {
    TEXT: "Email cannot be empty. Please type your email.",
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  EMAIL_INVALID: {
    TEXT: "Please insert a valid email.",
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  EMAIL_ERROR: {
    TEXT: "An error occured. Please refresh the page and try again.",
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  INSERT_CODE: {
    TEXT: `Code sent! Check your mail inbox and insert the 6 digits number that we have sent you, below. 
        It may take a few moments to arrive (remeber to check your spam/junk folder if you don't find it there)`,
    HEIGHT: api.utils.isMobile() ? 130 : 80,
  },
  CODE_DO_NOT_MATCH: {
    TEXT: `Verification code not matching. Please check again.`,
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  CODE_EMPTY: {
    TEXT: `Code cannot be empty. Please type the code we just sent you.`,
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
  CODE_ERROR: {
    TEXT: `Ops... An error has occured, please try again`,
    HEIGHT: api.utils.isMobile() ? 130 : 50,
  },
};

export const UserVerificationForm = (props) => {
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [codeMessage, setCodeMessage] = useState(INSTRUCTIONS.INSERT_CODE.TEXT);
  const [
    verificationCodeNotMatching,
    setVerificationCodeNotMatching,
  ] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailMessage, setEmailMessage] = useState(
    INSTRUCTIONS.INSERT_EMAIL.TEXT
  );
  const [codeError, setCodeError] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [loading, setLoading] = useState(false);

  function changeState(e) {
    const { name, value } = e.target;
    props.changeState(name, value);
  }
  function onChange(e) {
    const { value } = e.target;
    setVerificationCode(value);
  }

  async function sendCode() {
    setOpacity(0);
    setTimeout(async () => {
      animate(true, 1);
      const { email } = props;
      const validEmail = isValidEmail(email);
      if (validEmail) {
        const response = await api.checkout.sendVerificationCode(email);
        try {
          setOpacity(0);
          if (response.data.message === "Queued. Thank you.") {
            setTimeout(() => {
              setVerificationCodeSent(true);
              props.changeState("closeModalfromBg", false); // disable closing Modal when clicking in background
              animate(false, 1);
            }, OPACITY_ANIMATION_DURATION);
          } else {
            setTimeout(() => {
              setEmailMessage(INSTRUCTIONS.EMAIL_ERROR.TEXT);
              setEmailError(true);
              animate(false, 1);
            }, OPACITY_ANIMATION_DURATION);
          }
        } catch (exception) {
          setTimeout(() => {
            setEmailMessage(INSTRUCTIONS.EMAIL_ERROR.TEXT);
            setEmailError(true);
            animate(false, 1);
          }, OPACITY_ANIMATION_DURATION);
        }
      }
    }, OPACITY_ANIMATION_DURATION);
  }

  function isValidEmail(email) {
    const validEmailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );
    const emailNotEmpty = api.utils.validateTextInput(email);
    if (validEmailFormat && emailNotEmpty) {
      return true;
    } else if (!validEmailFormat && emailNotEmpty) {
      animate(false, 1);
      setEmailMessage(INSTRUCTIONS.EMAIL_INVALID.TEXT);
      setEmailError(true);
      return false;
    } else {
      animate(false, 1);
      setEmailMessage(INSTRUCTIONS.EMAIL_EMPTY.TEXT);
      setEmailError(true);
      return false;
    }
  }

  function animate(loading, opacity) {
    setLoading(loading);
    setOpacity(opacity);
  }

  function checkCode() {
    setOpacity(0);
    const validatedCode = api.utils.validateTextInput(verificationCode);
    if (validatedCode) {
      setTimeout(async () => {
        try {
          animate(true, 1);
          const { email } = props;
          const responseCode = await api.checkout.checkVerificationCode(
            email,
            verificationCode
          );
          if (responseCode.data.code === 200) {
            const responseUserDetails = await api.checkout.getUserDetails(
              email
            );
            if (responseUserDetails.data.code === 200) {
              let result = responseUserDetails.data.result;
              props.changeState("name", result.name);
              props.changeState("address", result.address_line1);
              props.changeState("address_line_2", result.address_line2);
              props.changeState("city", result.city);
              props.changeState("postcode", result.postcode);
              props.changeState("country", { name: result.country });
              props.changeState("userFound", true);
            }
            props.changeState("userVerified", true);
          } else {
            setVerificationCodeNotMatching(true);
            setCodeMessage(INSTRUCTIONS.CODE_DO_NOT_MATCH.TEXT);
            animate(false, 1);
          }
        } catch (exception) {
          setVerificationCodeNotMatching(true);
          props.changeState("userVerified", false);
          setCodeError(true);
          setCodeMessage(INSTRUCTIONS.CODE_ERROR.TEXT);
          animate(false, 1);
        }
      }, OPACITY_ANIMATION_DURATION);
    } else {
      setTimeout(() => {
        setOpacity(1);
        setCodeError(true);
        setVerificationCodeNotMatching(true);
        setCodeMessage(INSTRUCTIONS.CODE_EMPTY.TEXT);
      }, OPACITY_ANIMATION_DURATION);
    }
  }

  function onPress() {
    if (!verificationCodeSent) {
      return sendCode();
    } else checkCode();
  }

  return (
    <div data-test="wrapper" className="user-verification-form-wrapper">
      <div
        data-test="content"
        className="user-verification-form-input-wrapper"
        style={{ opacity }}
      >
        {!verificationCodeSent && !loading ? (
          <React.Fragment>
            <div
              data-test="email-form-content"
              className="user-verification-form-instruction-wrapper center"
            >
              <InfoBox
                data-test="email-infobox"
                text={emailMessage}
                height={api.utils.isMobile() ? 70 : 50}
                style={emailError ? "error" : "info"}
              />
            </div>
            <InputBox
              data-test="email-input"
              boxName="Email"
              type="email"
              name="email" // this related to the parent state.. do not change
              placeholder="Email address"
              value={props.email}
              setValue={changeState}
              error={emailError}
              onKeyPress={onPress}
            />
          </React.Fragment>
        ) : !loading ? (
          <div>
            <div
              data-test="code-form-content"
              className="user-verification-form-instruction-wrapper center"
            >
              <InfoBox
                data-test="code-infobox"
                text={codeMessage}
                height={
                  !verificationCodeNotMatching
                    ? INSTRUCTIONS.INSERT_CODE.HEIGHT
                    : !codeError
                    ? INSTRUCTIONS.CODE_DO_NOT_MATCH.HEIGHT
                    : INSTRUCTIONS.CODE_ERROR.HEIGHT
                }
                style={!verificationCodeNotMatching ? "info" : "error"}
              />
            </div>
            <InputBox
              data-test="code-input"
              boxName="Code"
              type="number"
              name="code"
              placeholder="Verification code"
              value={verificationCode}
              setValue={onChange}
              error={codeError}
              pattern="[0-9]*"
              inputMode="numeric"
              onKeyPress={onPress}
            />
            <p
              data-test="send-code-again"
              className="raleway-typography"
              style={{ marginTop: 8, marginBottom: 20 }}
              onClick={() => sendCode()}
            >
              Havent't received verification code? Send again.
            </p>
          </div>
        ) : null}
        {!loading ? (
          <button data-test="button" onClick={onPress}>
            {!verificationCodeSent ? "SEND CODE" : "AUTHENTICATE"}
          </button>
        ) : null}

        {loading ? (
          <div
            data-test="loader-wrapper"
            className="user-verification-form-loading center"
          >
            <Loader
              data-test="loader"
              type="Watch"
              color="gray"
              height={70}
              width={70}
              timeout={0} //3 secs
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default withStyles(styles, globalStyle)(UserVerificationForm);
