import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./common.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import success_svg from "../../../../../../resources/svg/tick.svg";
import error_svg from "../../../../../../resources/svg/error-coloured.svg";

export const CHECKOUT_RESPONSE = {
  SUCCESS: {
    SVG: success_svg,
    TITLE: "THANK YOU!",
    SUBTITLE: "Your donation was sent successfully.",
    DESCRIPTION:
      "As a church we want to thank you for your generous contribution.",
  },
  ERROR: {
    SVG: error_svg,
    TITLE: "Oh No!",
    SUBTITLE: "An error has occured.",
    DESCRIPTION:
      "An error has occurred when processing your transaction. Don't worry, your card has not been charged.",
  },
  DECLINED: {
    SVG: error_svg,
    TITLE: "Card Declined!",
    SUBTITLE: "",
    DESCRIPTION:
      "Your card has been declined. Please check your payment method or use a different card.",
  },
};

export const ResponseCard = (props) => {
  let response_status = CHECKOUT_RESPONSE.ERROR;
  if (props.paymentStatus === props.paymentStatusObj.SUCCESS) {
    response_status = CHECKOUT_RESPONSE.SUCCESS;
  }
  if (props.paymentStatus === props.paymentStatusObj.DECLINED) {
    CHECKOUT_RESPONSE.DECLINED.SUBTITLE = props.additionalMessage;
    response_status = CHECKOUT_RESPONSE.DECLINED;
  }

  return (
    <div data-test="wrapper" className="cop-status-card-content center column">
      <img data-test="img" src={response_status.SVG} alt="response_status" />
      <h3 data-test="title" className="raleway-typography">
        {response_status.TITLE}
      </h3>
      <h4 data-test="subtitle" className="raleway-typography">
        {response_status.SUBTITLE}
      </h4>
      <p data-test="description">{response_status.DESCRIPTION}</p>
    </div>
  );
};

export default withStyles(styles, globalStyle)(ResponseCard);
