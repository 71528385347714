import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import desktop_svg from "../../../../../resources/svg/desktop.svg";
import envelope_svg from "../../../../../resources/svg/envelope.svg";
import giftAid_svg from "../../../../../resources/svg/gift-aid.svg";

const Item = (props) => {
  return (
    <div className="gp-paymentoptions-item flex align-center justify-start column">
      <img src={props.svg} alt="icon" />
      <h3>{props.title}</h3>
      <p>{props.description}</p>
      {props.component}
    </div>
  );
};

function scroll(section) {
  const anchor = document.querySelector(section);
  anchor.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "start",
  });
}

const PaymentOptions = (props) => {
  return (
    <section className="gp-paymentoptions-wrapper center column">
      <div className="gp-paymentoptions-header center">
        <h1 className="raleway-typography">Ways to give</h1>
      </div>
      <div className="gp-paymentoptions-content flex align-start justify-around">
        <Item
          svg={desktop_svg}
          title="Online Giving"
          description="Give online in less than a minute using your email and credit/debit card to give securely."
          btnName="GIVE"
          component={
            <button
              onClick={() => props.history.push("/check-out")}
              className="center"
            >
              GIVE ONLINE NOW
            </button>
          }
        />
        <Item
          svg={envelope_svg}
          title="Weekend Services"
          description="At each of our services, you'll find a Giving Envelope under your seat. Don't forget Gift Aid!"
          btnName="FIND LOCATION"
          onClick={() => {}}
          component={
            <a href="/#locations" className="center">
              FIND LOCATION
            </a>
          }
        />
        <Item
          svg={giftAid_svg}
          title="Gift Aid"
          description="Make the most of your giving. If you are a UK taxpayer, don't forget to register for Gift Aid!"
          btnName="FIND OUT MORE"
          component={
            <button onClick={() => scroll("#gift-aid")} className="center">
              FIND OUT MORE
            </button>
          }
        />
      </div>
    </section>
  );
};
export default withRouter(withStyles(styles, globalStyle)(PaymentOptions));
