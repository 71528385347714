import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { production_mode } from "../../config/config_variables";

export const submitPayment = async (
  stripe,
  cardElement,
  email,
  amount,
  currency,
  donation_type,
  name,
  city,
  line1,
  line2,
  postal_code,
  country,
  location
) => {
  try {
    let cloudFunctionName = "createPaymentIntent";
    if (!production_mode) cloudFunctionName = "test_createPaymentIntent";
    var createPaymentIntent = firebase
      .functions()
      .httpsCallable(cloudFunctionName);
    const paymentIntent = await createPaymentIntent({
      email,
      amount,
      currency,
      payment_method_types: ["card"],
      donation_type,
      location,
    });
    // console.log(paymentIntent);
    if (paymentIntent !== 503) {
      let { error } = await stripe.handleCardPayment(
        paymentIntent.data.client_secret,
        cardElement,
        {
          payment_method_data: {
            billing_details: {
              address: {
                city,
                line1,
                line2,
                postal_code,
                country,
              },
              name,
              email,
            },
          },
        }
      );
      if (error) {
        // Display error.message in your UI.
        // console.log(error);
        return error;
      }
      return paymentIntent;
    }
    return 503;
  } catch (exception) {
    console.log(exception);
    return 503;
  }
};
