import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

export const Message = () => {
  return (
    <section
      data-test="mission-message-wrapper"
      className="mp-message-wrapper center column"
    >
      <h2 data-test="mission-message-title">
        WE CAN, AND WILL, MAKE A DIFFERENCE
      </h2>
      <p data-test="mission-message-subtitle">
        In our world with so much need, it can often feel overwhelming and
        impossible to see how we can really make a difference in the lives of
        people trapped in poverty and injustice.
      </p>
    </section>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Message));
