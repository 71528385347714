import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyles from "../global-styles/style.css";
import Link from "./link";
import links from "./links.json";
import LanguageDropdown from "./component/language-dropdown";
import { ReactComponent as DownArrow } from "../../../resources/svg/down-arrow.svg";
import { setBaseUrl } from "../../../redux/actions/setBaseUrl";
// import { languages } from "../../../languages";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkColor: "white",
      displayLanguageDropdown: false,
    };
    this.navBar = React.createRef;
    this.h1_logo = React.createRef;
    this.h3_logo = React.createRef;
    this.languageContainerRef = React.createRef;
    this.scrollEventListener = this.scrollEventListener.bind(this);
  }

  componentDidMount() {
    this.onScroll();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEventListener);
  }

  onScroll(e) {
    const isBrowser = typeof window !== `undefined`;
    if (!isBrowser) return;
    window.addEventListener("scroll", this.scrollEventListener);
  }

  scrollEventListener() {
    let offset = window.scrollY;
    try {
      if (this.props.location.pathname === "/check-out") return;
      if (offset > 300) {
        const boxShadow =
          "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
        const background = "white";
        const linkColor = "black";
        this.setNavbarStyle(boxShadow, background, linkColor);
      } else {
        const boxShadow = "unset";
        const background = "unset";
        const linkColor = "white";
        this.setNavbarStyle(boxShadow, background, linkColor);
      }
    } catch (err) {
      console.log(err);
    }
  }

  setNavbarStyle(boxShadow, background, linkColor) {
    this.navBar.style.boxShadow = boxShadow;
    this.navBar.style.background = background;
    this.setState({ linkColor });
  }

  changeLanguage = (lang) => {
    // let currentUrl = this.props.location.pathname;
    // let currentUrlLang = currentUrl.split("/")[1];
    // for (let i = 0; i < languages.length; i++) {
    //   if (currentUrlLang === languages[i].name) {
    //     currentUrl = currentUrl.replace(`/${currentUrlLang}`, "");
    //   }
    // }
    // let redirectTo = `/${lang}/${currentUrl}`;
    // redirectTo = redirectTo.replace("//", "/");
    // this.props.setBaseUrl(`${lang}/`);
    // this.props.history.push(redirectTo);
  };

  render() {
    const { linkColor } = this.state;
    const { language } = this.props;
    return (
      <div className="navbar-wrapper" ref={(node) => (this.navBar = node)}>
        <NavLink
          exact
          to={this.props.baseUrl}
          className="navbar-logo-wrapper flex align-start justify-center column"
        >
          <h1 style={{ color: linkColor }}>LVD CHURCH | UK</h1>
          <h3 style={{ color: linkColor }}>MINISTRIES</h3>
        </NavLink>
        <div className="navbar-links-wrapper center">
          {links.map((link, idx) => (
            <Link
              key={idx}
              linkName={link.name}
              toLink={`${this.props.baseUrl}${link.path}`}
              onPress={null}
              color={linkColor}
            />
          ))}
        </div>
        <div className="navbar-empty-wrapper">
          <div
            ref={(node) => (this.languageContainerRef = node)}
            className="navbar-language-wrapper center"
            onClick={() =>
              this.setState({
                displayLanguageDropdown: !this.state.displayLanguageDropdown,
              })
            }
          >
            <img
              src={require(`../../../resources/svg/flags/rounded/${language.img}`)}
              alt="flag"
            />
            <p className="raleway-typography" style={{ color: linkColor }}>
              {language.code}
            </p>
            <div
              className={`${
                this.state.displayLanguageDropdown
                  ? "navbar-language-wrapper-svg-reverse"
                  : "navbar-language-wrapper-svg-normal"
              }`}
            >
              <DownArrow width={10} height={10} fill={this.state.linkColor} />
            </div>
            {this.state.displayLanguageDropdown ? (
              <LanguageDropdown
                containerRef={this.languageContainerRef}
                changeLanguage={this.changeLanguage}
                toggleDropdown={() =>
                  this.setState({
                    displayLanguageDropdown: !this.state
                      .displayLanguageDropdown,
                  })
                }
              />
            ) : null}
          </div>
          <div className="navbar-menu-wrapper">
            <div
              className="hambburger-wrapper center column"
              onClick={this.props.onPress}
            >
              <div
                className="humburger-line"
                style={{ backgroundColor: linkColor }}
              />
              <div
                className="humburger-line"
                style={{ backgroundColor: linkColor }}
              />
              <div
                className="humburger-line"
                style={{ backgroundColor: linkColor }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { language: state.language, baseUrl: state.baseUrl };
}

export default connect(mapStateToProps, { setBaseUrl })(
  withRouter(withStyles(styles, globalStyles)(Navbar))
);
