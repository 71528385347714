import { languages } from "../../languages";
import { SET_BASEURL } from "./setBaseUrl";

export const SET_LANGUAGE = "set_language";

const defaultLanguageIdx = 0;

export const setLanguage = (URL, history) => async (dispatch, getState) => {
  let currentLanguage = getState().language.name;
  let URLlanguage = URL.split("/");
  if (URLlanguage.length === 0) return;
  URLlanguage = URLlanguage[1];

  if (URLlanguage === languages[defaultLanguageIdx].name) {
    URL = URL.replace(`${URLlanguage}/`, "");
    dispatchAction(dispatch, SET_BASEURL, "/");
    dispatchAction(dispatch, SET_LANGUAGE, languages[defaultLanguageIdx]);
    return history.push(URL);
  }

  if (URLlanguage === currentLanguage) return;

  for (let i = 0; i < languages.length; i++) {
    if (languages[i].name === URLlanguage) {
      let baseUrl = `/${languages[i].name}/`;
      if (getState().baseUrl !== `/${URLlanguage}/`) {
        dispatchAction(dispatch, SET_BASEURL, baseUrl);
      }
      return dispatchAction(dispatch, SET_LANGUAGE, languages[i]);
    }
  }
  return dispatchAction(dispatch, SET_LANGUAGE, languages[defaultLanguageIdx]);
};

function dispatchAction(dispatch, type, payload) {
  return dispatch({
    type,
    payload,
  });
}
