import { utils } from "./utils";
import { events } from "./events";
import { mail } from "./mail";
import { currency } from "./currency";
import { checkout } from "./checkout";
import { firebaseStorage } from "./firebase-storare";

export const api = {
  utils,
  events,
  mail,
  currency,
  checkout,
  firebaseStorage
};
