import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../components/parallax-container";
import hero_img from "../../../../../resources/images/hero/hero2.png";

class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        parallaxContainerHeight="100vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.3)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.3)"
        minHeight={500}
      >
        <div
          className="aboutpage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h2 className="aboutpage-hero-section-subheadline white raleway-typography">
            ABOUT
          </h2>
          <h1 className="aboutpage-hero-section-headline white">
            LVD CHURCH <span>|</span> UK
          </h1>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
