import React, { useRef, useState, useEffect } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import downArrow_svg from "../../../resources/svg/down-arrow.svg";
import countries from "./countries.json";

const CountryDropdown = (props) => {
  let dropdownButton = useRef(null);
  let dropdownRef = useRef(null);
  const [dropdownToggleClass, setDropdownToggleClass] = useState("");

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown, false);
    return () => {
      document.removeEventListener("mousedown", closeDropdown, false);
    };
  });

  function toggleDropdown() {
    setDropdownToggleClass(
      dropdownToggleClass === "show-country-dropdown"
        ? ""
        : "show-country-dropdown"
    );
  }

  function closeDropdown(e) {
    if (
      !dropdownRef.contains(e.target) &&
      !dropdownButton.contains(e.target) &&
      dropdownToggleClass === "show-country-dropdown"
    ) {
      toggleDropdown();
    }
  }

  function select(option) {
    props.setCountry("country", option);
    toggleDropdown();
  }
  return (
    <div className="country-dropdown-input-content-wrapper flex align-center justify start">
      <div className="country-dropdown-input-box-placeholder-wrapper flex align-center justify-start">
        <h5>{props.boxName}</h5>
      </div>
      <div className="country-dropdown-input-box-input-wrapper">
        <div className="country-dropdown">
          <h5>{props.dropdownTitle}</h5>
          <button
            data-test="country-dropdown-btn"
            onClick={toggleDropdown}
            className="country-dropbtn center"
            ref={(node) => (dropdownButton = node)}
          >
            <div className="country-dropdown-selected-option-wrapper flex align-start justify-center">
              <h4>{props.countrySelected.name}</h4>
            </div>
            <div className="country-dropdown-icon-wrapper center">
              <img
                src={downArrow_svg}
                alt="down-arrow"
                style={{ width: 10, height: 10 }}
              />
            </div>
          </button>
        </div>
      </div>
      <div
        data-test="country-dropdown-wrapper"
        className={`country-dropdown-content ${dropdownToggleClass}`}
        ref={(node) => (dropdownRef = node)}
      >
        {countries.map((option, index) => {
          return (
            <p
              data-test="country-option"
              key={index}
              onClick={() => select(option)}
            >
              {option.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles, globalStyle)(CountryDropdown);
