import App from "./App";
import HomePage from "./pages/Home";
import Groups from "./pages/Communities/Groups/index";
import Sisterhood from "./pages/Communities/Sisterhood";
import Youth from "./pages/Communities/Youth";
import Calendar from "./pages/Calendar";
import EventPage from "./pages/EventPage";
import About from "./pages/About";
import Prayer from "./pages/Prayer";
import Mission from "./pages/Mission";
import Giving from "./pages/Giving";
import GiftAid from "./pages/GiftAid";
import NotFoundPage from "./pages/NotFoundPage";

import { languages } from "../languages";

const constructPath = (page) => {
  let path = [page];
  for (let i = 0; i < languages.length; i++) {
    path.push(`/${languages[i].name}${page}`);
  }
  return path;
};

const constructBase = () => {
  let path = ["/"];
  for (let i = 0; i < languages.length; i++) {
    path.push(`/${languages[i].name}`);
  }
  return path;
};

const base = constructBase();
const groups = constructPath("/groups");
const sisterhood = constructPath("/sisterhood");
const youth = constructPath("/youth");
const calendar = constructPath("/calendar");
const eventpage = constructPath("/calendar/:eventId");
const about = constructPath("/about");
const prayer_request = constructPath("/prayer-request");
const mission = constructPath("/mission");
const giving = constructPath("/giving");
const check_out = constructPath("/check-out");
const gift_aid = constructPath("/gift-aid");

// look for react-router-config library docs
export default [
  {
    ...App,
    routes: [
      {
        ...HomePage,
        path: base,
        exact: true,
      },
      {
        ...Groups,
        path: groups,
        exact: true,
      },
      {
        ...Sisterhood,
        path: sisterhood,
        exact: true,
      },
      {
        ...Youth,
        path: youth,
        exact: true,
      },
      {
        ...Calendar,
        path: calendar,
        exact: true,
      },
      {
        ...EventPage,
        path: eventpage,
        exact: true,
      },
      {
        ...About,
        path: about,
        exact: true,
      },
      {
        ...Prayer,
        path: prayer_request,
        exact: true,
      },
      {
        ...Mission,
        path: mission,
        exact: true,
      },
      {
        ...Giving,
        path: giving,
        exact: true,
      },
      {
        ...Giving,
        path: check_out,
        exact: true,
      },
      {
        ...GiftAid,
        path: gift_aid,
        exact: true,
      },
      {
        ...NotFoundPage,
      },
    ],
  },
];
