import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

const GiftAid = () => {
  return (
    <section className="gp-giftaid-wrapper flex center column" id="gift-aid">
      <h1 className="raleway-typography">Gift Aid</h1>
      <p className="raleway-typography">
        Make the most of your giving. Boost your donation by 25p of Gift Aid for
        every £1 you donate. If you are a UK taxpayer, register for Gift Aid.
        <br /> <br />
        Gift Aid allows us to reclaim basic tax paid on donations made by UK
        taxpayers. This means that if you give £100.00 and make a Gift Aid
        Declaration, your overall contribution will now be worth £125.00.
        <br /> <br />
        If you choose to donate via our online giving we’ll prompt you to
        register for Gift Aid whilst you give. If you’re giving in person in one
        of our location we’ll provide you with the Gift Aid form for you to
        fill.
        <br /> <br />
        Or why not complete a Gift Aid Declaration form now, using our online
        form below.
      </p>
      <a href="/gift-aid" className="center">
        GIFT AID DECLARATION FORM
      </a>
    </section>
  );
};

export default withRouter(withStyles(styles, globalStyle)(GiftAid));
