import firebase from "firebase/app";
import "firebase/firestore";

export const FETCH_SINGLE_EVENT = "fetch_single_event";

export const fetchSingleEvent = (eventId) => async (dispatch, getState) => {
  const db = firebase.firestore();
  return db
    .doc(`events/${eventId}`)
    .get()
    .then(async (event) => {
      if (event.exists) {
        let evt = event.data();
        return dispatch({
          type: FETCH_SINGLE_EVENT,
          payload: evt,
        });
      }
      return dispatch({
        type: FETCH_SINGLE_EVENT,
        payload: null,
      });
    })
    .catch((err) => {
      console.log("***", eventId, err);
      return dispatch({
        type: FETCH_SINGLE_EVENT,
        payload: null,
      });
    });
};
