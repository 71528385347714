import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import eventsReducer from "./eventsReducer";
import eventReducer from "./eventReducer";
import footerReducer from "./footerReducer";
import languageReducer from "./languageReducer";
import baseUrlReducer from "./baseUrlReducer";

export default combineReducers({
  users: usersReducer,
  events: eventsReducer,
  event: eventReducer,
  footer: footerReducer,
  language: languageReducer,
  baseUrl: baseUrlReducer,
});
