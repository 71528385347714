import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import style from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import { api } from "../../../../../api";
import { NavLink } from "react-router-dom";

const EventCard = (props) => {
  return (
    <NavLink
      className="event-card-wrapper"
      to={{
        pathname: `/calendar/${props.event.id}`,
        data: { event: props.event },
      }}
      exact
    >
      <div className="event-card-img-wrapper">
        <img src={props.event.imageURL} alt="event" />
      </div>
      <div className="event-card-content-wrapper flex align-start justify-center column">
        <h3 className="raleway-typography">{props.event.name}</h3>
        <p className="event-card-date raleway-typography">
          {api.utils.timestampConverter(props.event.date).date}
        </p>
        <p className="event-card-address raleway-typography">
          {String(props.event.location).toUpperCase()}
        </p>
      </div>
    </NavLink>
  );
};

export default withRouter(withStyles(style, globalStyle)(EventCard));
