import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../components/parallax-container";
import { api } from "../../../../../api";

class Hero extends React.Component {
  render() {
    const { lang } = this.props;
    return (
      <ParallaxContainer
        parallaxContainerHeight="100vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        // img={`url(${home_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="#040304"
        bottomLinearGradientColor="#040304"
      >
        {!api.utils.isMobile() ? (
          <video autoPlay muted loop id="home-video-container">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/lvd-church-uk.appspot.com/o/videos%2Fhome_video_loop.mp4?alt=media&token=5b204089-4f07-4a38-b48a-153a87936f22"
              type="video/mp4"
            />
          </video>
        ) : null}

        <div
          className="homepage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h1 className="homepage-hero-section-headline white">{lang.title}</h1>
          <h1
            className="homepage-hero-section-headline white"
            style={{ marginTop: -50 }}
          >
            {lang.titleSpan}
          </h1>
          <h2 className="homepage-hero-section-subheadline white">
            {lang.subtitle}
          </h2>
          <a
            href="https://www.facebook.com/LVDChurchUK"
            target="_blank"
            rel="noopener noreferrer"
            className="homepage-hero-section-btn white"
          >
            {lang.btnText}
          </a>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
