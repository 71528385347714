import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export const sendVerificationCode = (email) => {
  var sendCode = firebase.functions().httpsCallable("sendVerificationCode");
  return sendCode({
    sender: "giving@lvdchurchuk.org",
    receiver: email,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
