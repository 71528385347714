import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import Loader from "react-loader-spinner";
import style from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import EventCard from "../event-card";
// import firebase from "firebase";
// import { api } from "../../../../../api";

export const CalendarList = (props) => {
  /* ***** VERY IMPORTANT!!!!! *****  -- DO NOT DELETE -- */
  // const date1 = new Date("2020-05-03T10:00:00");
  // const timestamps1 = firebase.firestore.Timestamp.fromDate(date1);
  // const date2 = new Date("2020-05-10T10:30:00");
  // const timestamps2 = firebase.firestore.Timestamp.fromDate(date2);
  // const date3 = new Date("2020-05-17T10:30:00");
  // const timestamps3 = firebase.firestore.Timestamp.fromDate(date3);
  // const date4 = new Date("2020-05-24T10:00:00");
  // const timestamps4 = firebase.firestore.Timestamp.fromDate(date4);
  // const date5 = new Date("2020-05-31T10:00:00");
  // const timestamps5 = firebase.firestore.Timestamp.fromDate(date5);
  // const date6 = new Date("2020-06-07T10:00:00");
  // const timestamps6 = firebase.firestore.Timestamp.fromDate(date6);
  // const date7 = new Date("2020-06-14T10:00:00");
  // const timestamps7 = firebase.firestore.Timestamp.fromDate(date7);
  // const date8 = new Date("2020-06-21T10:00:00");
  // const timestamps8 = firebase.firestore.Timestamp.fromDate(date8);
  // const date9 = new Date("2020-06-28T10:00:00");
  // const timestamps9 = firebase.firestore.Timestamp.fromDate(date9);

  // const evt = [
  // {
  //   address: "Facebook Live",
  //   date: timestamps1,
  //   description:
  //     "Church is about God and people. We pray you will find purpose, fulfillment and joy as you find your place in the life of LVD Church.See you there!",
  //   image: "church-service.jpg",
  //   imageURL:
  //     "https://firebasestorage.googleapis.com/v0/b/lvd-church-uk.appspot.com/o/images%2Fevents%2Fonline-service.png?alt=media&token=fa4d4337-b909-4afc-821b-5483e50af53b",
  //   link: "https://www.facebook.com/LVDChurchUK",
  //   linkBtnText: "Join Online",
  //   location: "ONLINE",
  //   name: "Online Service",
  // }
  //   {
  //     image: "sisterhood-group.jpg",
  //     imageURL:
  //       "https://firebasestorage.googleapis.com/v0/b/lvd-church-uk.appspot.com/o/images%2Fevents%2Fsisterhood-group.jpg?alt=media&token=d3349aa5-f8b6-435d-9e26-c0f73deedc4d",
  //     name: "Sisterhood meeting",
  //     date: timestamps4,
  //     location: "Crawley",
  //     description:
  //       "Join us in June for a Friday night out with friends! It will be a relaxed and fun evening, with some bubbles, nibbles and of course, Real Talk with inspiring women.\n\nWe would love to see you all there – we are better together!",
  //     address: "Langley Green, Martyrs Avenue, Rh11 7RX Crawley"
  //   },
  //   {
  //     image: "bible-school-group.jpg",
  //     imageURL:
  //       "https://firebasestorage.googleapis.com/v0/b/lvd-church-uk.appspot.com/o/images%2Fevents%2Fbible-school-group.jpg?alt=media&token=ea9f20e2-4017-4002-9b1f-27f4e9551bbb",
  //     name: "Bible School",
  //     date: timestamps7,
  //     location: "London",
  //     description:
  //       "Our Bible School offers a wide range of topics and life-changing teaching that are designed for people who wish to wish to increase their knowledge and understanding of the Bible. These courses are built to empower you to impact the world and to gain a greater theological knowledge in your Christian journey.\n\nBible School takes place on Thursday evenings from 6:00-8:30pm at our Crawley Campus.",
  //     address: "Crown Ln, Surrey SM4 5BL"
  //   }
  // ];

  // function addEventToFirebase() {
  //   evt.forEach(async (e) => {
  //     const addedEvents = await api.events.add(e);
  //     console.log(addedEvents);
  //   });
  // }

  if (
    (props.events.length === 0 || props.events === undefined) &&
    !props.noEventsFound
  ) {
    return (
      <section
        className="calendar-list-wrapper center"
        data-test="loader-wrapper"
      >
        <Loader
          data-test="loader"
          type="Watch"
          color="black"
          height={100}
          width={100}
          timeout={0} //3 secs
        />
      </section>
    );
  }

  if (props.noEventsFound) {
    return (
      <section
        className="calendar-list-wrapper center"
        data-test="no-events-wrapper"
      >
        <h5 data-test="no-events-text">No events coming up.</h5>
      </section>
    );
  }

  return (
    <section className="calendar-list-wrapper center" data-test="wrapper">
      {/* <button onClick={() => addEventToFirebase()}>Add</button>*/}
      {props.events.map((event) => (
        <EventCard
          data-test="card-event-wrapper"
          key={event.id}
          event={event}
        />
      ))}
    </section>
  );
};

export default withRouter(withStyles(style, globalStyle)(CalendarList));
