import React from "react";
import { NavLink } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";

export const Giving = () => {
  return (
    <section
      data-test="mission-giving-wrapper"
      className="ap-giving-wrapper center column"
    >
      <h2 data-test="mission-giving-title" className="raleway-typography white">
        JOIN US IN MAKING A DIFFERENCE
      </h2>
      <p
        data-test="mission-giving-paragraph"
        className="raleway-typography white"
      >
        Your contribution can help to bring light to darkness, peace to
        conflict, and hope to the hopeless. We strategically partner with
        organisations to support and facilitate sustainable projects and provide
        humanitarian assistance in Madagascar, combining our respective
        strengths and influence for greater impact.
      </p>
      <NavLink
        data-test="mission-giving-btn"
        className="mp-giving-btn raleway-typography"
        to="/giving"
        exact
      >
        DONATE TODAY
      </NavLink>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Giving);
