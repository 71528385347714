import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";

const Item = (props) => {
  return (
    <div
      className="hp-communities-item center column"
      style={{
        marginBottom: props.marginBottom ? "" : "unset",
      }}
    >
      <h2 className="oswald-typography">{props.title}</h2>
      <p className="raleway-typography">{props.description}</p>
      <div
        className="hp-communities-moreInfo-btn center"
        onClick={() => props.history.push(props.link)}
      >
        <p>{props.btnText}</p>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Item));

Item.defaultProps = {
  img: null,
  imgFrameColor: "black",
  title: "TITLE HERE",
  description: "Description here",
  btnText: "MORE INFO",
  backgroundPositionY: null,
  marginBottom: true,
};
