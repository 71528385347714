import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";
// import { HashLink as Link } from "react-router-hash-link";

const Description = () => {
  return (
    <React.Fragment>
      <section className="cp-youth-description-wrapper center column">
        <h2 className="pressstart2p-typography">Who we are</h2>
        <p className="pressstart2p-typography">
          "GENERATION AFTER GENERATION STANDS IN AWE OF YOUR WORK; EACH ONE
          TELLS STORIES OF YOUR MIGHTY ACTS" <br />
          psalm 145:4
        </p>
        <br />
        <br />
        <p className="pressstart2p-typography" style={{ color: "lightgray" }}>
          [ Some more words about LVD YOUTH Here ]
        </p>
      </section>
      <section className="cp-youth-join-wrapper center column">
        <h2>Want to join the crew?</h2>
        <p className="raleway-typography">
          Join us at one of our locations this Sunday and connect with our team
          to learn more or get in touch online.
        </p>
        <div className="cp-youth-join-btn-wrapper flex align-center justify-around ">
          <a className="cp-youth-join-btn center" href="/#locations">
            LOCATIONS
          </a>
          <a
            className="cp-youth-join-btn center"
            href={`mailto:lvdstaff@lvdchurchuk.org`}
          >
            GET IN TOUCH
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(withStyles(styles, globalStyle)(Description));
