import * as firebase from "firebase/app";
import "firebase/firestore";

import {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
} from "./config_variables";
export const init = () => {
  // Initialize Firebase
  var config = {
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
  };
  firebase.initializeApp(config);
  firebase.database();
};
