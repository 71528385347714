import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import style from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import { fetchSingleEvent } from "../../../redux/actions/fetchSingleEvent";
import { api } from "../../../api";
import Loader from "react-loader-spinner";

export class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      loading: true,
    };
  }
  async componentDidMount() {
    try {
      const { data } = this.props.location;
      if (data === undefined) {
        let pathName = this.props.location.pathname;
        let eventId = pathName.split("/")[2];
        let event = await this.props.fetchSingleEvent(eventId);
        if (event.payload === null) {
          return this.props.history.push("/NotFoundPage");
        }
        await this.setState({ event: event.payload, loading: false });
        document.title = `${this.state.event.name} | UK`;
      } else {
        await this.setState({ event: data.event, loading: false });
        document.title = `${this.state.event.name} | UK`;
      }
    } catch (exception) {
      console.log("error:", exception);
    }
  }
  render() {
    const { event } = this.state;
    if (this.state.loading) {
      return (
        <div
          className="eventpage-wrapper center column"
          data-test="wrapper-loading"
        >
          <Loader
            type="Watch"
            color="black"
            height={100}
            width={100}
            timeout={0} //3 secs
          />
        </div>
      );
    }
    if (event === null) {
      return <Redirect to="/NotFoundPage" />;
    }
    if (event === undefined) {
      return null;
    }

    return (
      <div className="eventpage-wrapper center column" data-test="wrapper">
        <div className="ep-image-wrapper">
          <img
            src={event.imageURL}
            alt="event"
            style={{
              objectPosition: event.imagePosition ? event.imagePosition : "top",
            }}
            data-test="img-banner"
          />
        </div>

        <div className="ep-content-wrapper">
          <h1 className="raleway-typography" data-test="event-title">
            {event.name}
          </h1>
          <h4 className="raleway-typography" data-test="event-date-time">
            {api.utils.timestampConverter(event.date).date}{" "}
            {api.utils.timestampConverter(event.date).time} | {event.location}
          </h4>
          <h6 data-test="event-adress">{event.address}</h6>
          <p className="raleway-typography" data-test="event-description">
            {event.description}
          </p>
          {event.link && event.linkBtnText ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={event.link}
              data-test="event-action-btn"
            >
              {event.linkBtnText}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { event: state.event };
}

function loadData(store, path) {
  path = String(path).split("/");
  let eventId = path[path.length - 1];
  return store.dispatch(fetchSingleEvent(eventId));
}

export default {
  loadData,
  component: connect(mapStateToProps, { fetchSingleEvent })(
    withStyles(style, globalStyle)(EventPage)
  ),
};
