import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../components/global-styles/style.css";
import Hero from "./components/hero";
import Description from "./components/description";

const Youth = () => {
  document.title = "Youth | UK";
  return (
    <div>
      <Hero />
      <Description />
    </div>
  );
};

export default {
  component: withStyles(styles, globalStyle)(Youth),
};
