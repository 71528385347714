import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";

const Link = ({ linkName, toLink, onPress, color }) => {
  //   const activeLinkColor = "white";
  return (
    <li onClick={onPress}>
      <NavLink
        className="link raleway-typography"
        activeStyle={
          {
            //   color: activeLinkColor,
            // borderBottom: `1px solid black`
          }
        }
        style={{ color }}
        exact
        to={toLink}
      >
        {linkName}
      </NavLink>
    </li>
  );
};

export default Link;
