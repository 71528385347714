import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export const getUserDetails = (email) => {
  var getUser = firebase.functions().httpsCallable("getUserDetails");
  return getUser({ email })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
