import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../../components/parallax-container";
import hero_img from "../../../../../../resources/images/hero/groups-hero.png";

class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        parallaxContainerHeight="60vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.6)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.6)"
        minHeight={500}
      >
        <div
          className="groupspage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h1 className="groupspage-hero-section-headline white">Groups</h1>
          <h2 className="groupspage-hero-section-subheadline white">
            Life was never meant to do alone. Join a group and experience
            relationships that will grow your faith
          </h2>
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
