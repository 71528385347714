import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";

class ParallaxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.parallaxImgContainer = React.createRef();
    this.parallaxOverlay = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
    this.parallaxOverlay.current.style.background = `linear-gradient(
        ${this.props.topLinearGradientColor},
        ${this.props.bottomLinearGradientColor}
      )`;
  }

  handleScroll() {
    try {
      const rect = this.parallaxImgContainer.current.getBoundingClientRect();
      var calc = 0;
      if (rect.top < window.innerHeight) {
        calc = (rect.top + (rect.top - 1)) * 0.8 - rect.top - rect.top - 1;
      }
      window.requestAnimationFrame(() => {
        this.parallaxImgContainer.current.style.transform = `translate3d(0, ${calc}px, 0)`;
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {
      parallaxContainerPosition,
      parallaxContainerWidth,
      parallaxContainerHeight,
      parallaxImgContainerHeight,
      parallaxImgContainerWidth,
      parallaxImgContainerBackgroundColor,
      parallaxImgContainerZIndex,
      img,
      imgRepeat,
      imgSize,
      imgPosition,
      backgroundPositionY,
      minHeight,
    } = this.props;
    return (
      <div
        className="parallax--container"
        style={{
          position: parallaxContainerPosition,
          height: parallaxContainerHeight,
          minHeight,
          width: parallaxContainerWidth,
          // background: "pink",
          overflow: "hidden",
        }}
        ref={this.parallaxContainer}
      >
        <div
          style={{
            position: "absolute",
            height: parallaxImgContainerHeight,
            width: parallaxImgContainerWidth,
            zIndex: parallaxImgContainerZIndex,
            background: parallaxImgContainerBackgroundColor,
            backgroundImage: img,
            backgroundRepeat: imgRepeat,
            backgroundSize: imgSize,
            backgroundPosition: imgPosition,
            backgroundPositionY: backgroundPositionY,
            // backgroundAttachment: imgAttachment
          }}
          ref={this.parallaxImgContainer}
        />
        <div ref={this.parallaxOverlay} className="parallax--overlay" />
        {this.props.children}
      </div>
    );
  }
}

export default withStyles(styles)(ParallaxContainer);

ParallaxContainer.defaultProps = {
  parallaxContainerPosition: "relative",
  parallaxContainerWidth: "100%",
  parallaxContainerHeight: 600,
  minHeight: 600,
  parallaxImgContainerHeight: "150%",
  parallaxImgContainerWidth: "100%",
  parallaxImgContainerBackgroundColor: "black",
  parallaxImgContainerZIndex: -1,
  img: "",
  imgRepeat: "no-repeat",
  imgSize: "cover",
  imgPosition: "center",
  imgAttachment: "fixed",
  backgroundPositionY: "unset",
  topLinearGradientColor: "rgba(0, 0, 0, 0.15)",
  bottomLinearGradientColor: "rgba(0, 0, 0, 0.15)",
};
