import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import InputBox from "../../../../components/input-box";
import CountryDropdown from "../../../../components/country-dropdown";
import UserCard from "./user-card";

export const UserForm = (props) => {
  function onChange(e) {
    const { name, value } = e.target;
    props.changeState(name, value);
  }
  function onCountryChange(name, value) {
    props.changeState(name, value);
  }

  const {
    userFound,
    name,
    nameError,
    email,
    emailError,
    address,
    addressError,
    address_line_2,
    city,
    cityError,
    country,
    postcode,
    postcodeError,
  } = props.state;
  return (
    <div
      className="checkout-user-form-wrapper flex align-center justify start column"
      data-test="wrapper"
    >
      <div
        className="checkout-billing-title-wrapper flex align-center justify-start"
        data-test="header"
      >
        <h2 data-test="header-text" className="raleway-typography">
          Billing Information
        </h2>
      </div>

      {!userFound ? (
        <React.Fragment>
          <div
            className="checkout-billing-input-wrapper"
            data-test="name-wrapper"
          >
            <InputBox
              data-test="name-input"
              boxName="Name"
              type="text"
              name="name" // this related to the parent state.. do not change
              placeholder="Full Name"
              value={name}
              setValue={onChange}
              error={nameError}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="email-wrapper"
          >
            <InputBox
              data-test="email-input"
              boxName="Email"
              type="email"
              autoCapitalize="off"
              name="email"
              placeholder="Email Address"
              value={email}
              setValue={onChange}
              error={emailError}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="address-wrapper"
          >
            <InputBox
              data-test="address-input"
              boxName="Address"
              type="text"
              name="address"
              placeholder="Address"
              value={address}
              setValue={onChange}
              error={addressError}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="address_line_2-wrapper"
          >
            <InputBox
              data-test="address_line_2-input"
              boxName=""
              type="text"
              name="address_line_2"
              placeholder="Line 2 (Optional)"
              value={address_line_2}
              setValue={onChange}
              error={addressError}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="city-wrapper"
          >
            <InputBox
              data-test="city-input"
              boxName="City"
              type="text"
              name="city"
              placeholder="City"
              value={city}
              setValue={onChange}
              error={cityError}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="country-wrapper"
          >
            <CountryDropdown
              data-test="country-input"
              boxName="Country"
              countrySelected={country}
              setCountry={onCountryChange}
            />
          </div>
          <div
            className="checkout-billing-input-wrapper"
            data-test="postcode-wrapper"
          >
            <InputBox
              data-test="postcode-input"
              boxName="Postcode"
              type="text"
              name="postcode"
              placeholder="Postcode"
              value={postcode}
              setValue={onChange}
              error={postcodeError}
            />
          </div>
        </React.Fragment>
      ) : (
        <UserCard
          data-test="user-card"
          state={props.state}
          setUserFound={onCountryChange}
        />
      )}
    </div>
  );
};

export default withStyles(styles, globalStyle)(UserForm);
