export const validateTextInput = text => {
  if (text.length !== 0 && text !== undefined && text !== null) {
    return true;
  }
  return false;
};

export const validateTextInputByComparison = (text, compareText) => {
  return (
    text === compareText &&
    text.length !== 0 &&
    text !== undefined &&
    text !== null
  );
};
