import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../../components/global-styles/style.css";
import ParallaxContainer from "../../../../../components/parallax-container";
import hero_img from "../../../../../../resources/images/hero/sisterhood-hero.jpg";

class Hero extends React.Component {
  render() {
    return (
      <ParallaxContainer
        parallaxContainerHeight="60vh"
        parallaxContainerWidth="100%"
        parallaxImgContainerHeight="150%"
        img={`url(${hero_img})`}
        backgroundPositionY={-100}
        topLinearGradientColor="rgba(0, 0, 0, 0.6)"
        bottomLinearGradientColor="rgba(0, 0, 0, 0.6)"
        minHeight={500}
      >
        <div
          className="sisterhoodpage-hero-container montserrat-typography"
          ref={this.parallaxContent}
        >
          <h1 className="sisterhoodpage-hero-section-headline white shrikhand-typography">
            Sisterhood
          </h1>
          {/*<h2 className="sisterhoodpage-hero-section-subheadline white">
            'You shall be a crown of beauty in the hand of the Lord, and a royal
            diadem in the hand of your God.'
            <br />
            Isaiah 62:3
    </h2>*/}
        </div>
      </ParallaxContainer>
    );
  }
}

export default withRouter(withStyles(styles, globalStyle)(Hero));
