import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import { withRouter } from "react-router-dom";
import PastRoroAndWifeImg from "../../../../../resources/images/Past-Roro-and-Wife.jpg";

const WelcomeSection = ({ lang }) => {
  return (
    <section className="homepage-welcome-section-wrapper center">
      <div className="homepage-welcome-section-content center">
        <div className="hp-content-side flex-start column">
          <h1 className="">{lang.title}</h1>
          <p className="raleway-typography" style={{ marginBottom: 5 }}>
            {lang.paragraph1}
          </p>

          <p className="raleway-typography">{lang.paragraph2}</p>
          <p className="hp-welcome-pastors-sig raleway-typography">
            {lang.pastNames}
          </p>
          <p className="hp-welcome-pastors-title">{lang.pastTitle}</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className="hp-content-side hp-welcome-right"
        >
          <img src={PastRoroAndWifeImg} alt="pastor" />
        </div>
      </div>
    </section>
  );
};

export default withRouter(withStyles(styles)(WelcomeSection));
