import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../../../components/global-styles/style.css";
import Dropdown from "../../../../components/dropdown";

export const CheckoutFilter = (props) => {
  return (
    <div className="checkout-filter-wrapper center column" data-test="wrapper">
      <div
        className="checkout-filter-content-wrapper flex align-center justify-start"
        data-test="location-wrapper"
      >
        <Dropdown
          data-test="location-dropdown"
          optionSelected={props.selectedLocation}
          setOption={(value) => props.changeState("selectedLocation", value)}
          options={props.locations}
          dropdownTitle="Choose location:"
        />
      </div>
      <div
        className="checkout-filter-content-wrapper flex align-center justify-start"
        style={{ marginBottom: 10 }}
        data-test="donation-wrapper"
      >
        <Dropdown
          data-test="donation-dropdown"
          optionSelected={props.selectedDonation}
          setOption={(value) => props.changeState("selectedDonation", value)}
          options={props.donations}
          dropdownTitle="Donate For:"
        />
      </div>
    </div>
  );
};

export default withStyles(styles, globalStyle)(CheckoutFilter);
