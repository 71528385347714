import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";
import Modal from "../modal";
import artImg from "../../../resources/images/christian-art.jpg";

const ConfirmationModal = props => {
  return (
    <Modal openModal={props.openModal} closeModal={() => props.closeModal()}>
      <div className="prayer-request-confirmation-modal-wrapper center column">
        <div
          className="prayer-request-confirmation-modal-header-wrapper center column"
          style={{ background: "green" }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${artImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center"
            }}
          />
        </div>
        <div className="prayer-request-confirmation-modal-content-wrapper center column">
          <h4 className="raleway-typography">{props.title}</h4>
          <p className="raleway-typography">{props.message}</p>
        </div>
        <div
          className="prayer-request-confirmation-modal-footer-wrapper center"
          onClick={props.closeModal}
        >
          <h5 className="raleway-typography" style={{ color: "white" }}>
            CLOSE
          </h5>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles, globalStyle)(ConfirmationModal);
