import React from "react";
import { Link } from "react-router-dom";
import withStyles from "isomorphic-style-loader/withStyles";
import globalStyle from "../../../../components/global-styles/style.css";
import styles from "./style.css";

const Giving = ({ lang }) => {
  return (
    <section className="hp-giving-wrapper center column">
      <div className="hp-giving-header center column">
        <h4 className="raleway-typography">{lang.upperTitle}</h4>
        <h1 className="raleway-typography">{lang.title}</h1>
        <p className="raleway-typography">{lang.subtitle}</p>
      </div>

      <div className="hp-giving-content flex align-center justify-around">
        <Link className="hp-giving-btn center" to="/giving">
          {lang.btnText1}
        </Link>
        <a className="hp-giving-btn center" href="/giving/#gift-aid">
          {lang.btnText2}
        </a>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(Giving);
