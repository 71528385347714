import React from "react";

const TextInput = props => {
  return (
    <div
      className="gap-textinput-wrapper"
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        width: props.width,
        minWidth: props.minWidth,
        height: props.displayError ? props.height + 50 : props.height,
        margin: props.margin
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: props.height,
          border: props.border,
          borderRadius: 5
        }}
      >
        <input
          style={{
            width: "95%",
            height: "90%",
            background: "none",
            outline: "none",
            border: "none",
            fontSize: "1em"
          }}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      {props.displayError ? (
        <p
          style={{
            width: props.width,
            height: 20,
            textAlign: "left",
            fontSize: 10,
            fontWeight: 400,
            color: props.errorColor
          }}
        >
          {props.errorMessage}
        </p>
      ) : null}
    </div>
  );
};

export default TextInput;

TextInput.defaultProps = {
  width: 100,
  maxWidth: 200,
  height: 50,
  border: "1px solid lightgrey",
  type: "text",
  name: "name",
  placeholder: "placeholder",
  value: "",
  onChange: null,
  errorColor: "red",
  errorMessage: "Error",
  displayError: false
};
