import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

export const checkVerificationCode = (email, code) => {
  var checkCode = firebase.functions().httpsCallable("checkVerificationCode");
  return checkCode({ email, code })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};
