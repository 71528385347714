import firebase from "firebase/app";
import "firebase/firestore";

export const add = async (eventObj) => {
  const db = firebase.firestore();
  const prefix_id = eventObj.name.replace(" ", "-");
  const postfix_id = await generateId();
  const eventID = prefix_id + postfix_id;
  return db
    .collection("events")
    .doc(eventID)
    .set({
      id: eventID,
      ...eventObj,
      // name: eventObj.name,
      // location: eventObj.location,
      // image: eventObj.image,
      // description: eventObj.description,
      // date: eventObj.date,
      // address: eventObj.address,
      // imageURL: eventObj.imageURL,
    })
    .then(() => {
      return {
        code: 200,
        response: "Event saved successfully",
      };
    })
    .catch((err) => {
      console.log(err);
      return {
        code: 500,
        response: "Ops... A problem has occurred.",
      };
    });
};

function generateId() {
  const min = 111111;
  const max = 999999;
  const id = Math.floor(Math.random() * (min - max + 1)) + min;
  return id;
}
