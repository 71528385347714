import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import menuStyle from "./menu.css";
import globalStyle from "../global-styles/style.css";
import Link from "./link";
import links from "./links.json";

const Menu = (props) => {
  return (
    <div
      className="nb-menu-wrapper flex"
      style={{ right: props.isOpen ? 0 : "-100vw" }}
    >
      <div className="nb-menu-close-btn center" onClick={props.onPress}>
        <img src={require("../../../resources/svg/close.svg")} alt="close" />
      </div>
      <div>
        {links.map((link, idx) => (
          <Link
            key={idx}
            linkName={link.name}
            toLink={link.path}
            onPress={props.onPress}
            color="white"
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(menuStyle, globalStyle)(Menu);
