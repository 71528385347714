import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import Hero from "./components/hero";
import CalendarList from "./components/calendar-list";
import { fetchEvents } from "../../../redux/actions/fetchEvents";

export class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      noEventsFound: false,
    };
  }
  componentDidMount = async () => {
    document.title = "All Events | UK";
    window.scrollTo(0, 0);
    let events = await this.props.fetchEvents();
    if (events.payload.length === 0) this.setState({ noEventsFound: true });
    this.setState({ events: events.payload });
  };

  render() {
    return (
      <div className="calendarpage_container">
        <Hero data-test="hero-component" />
        <CalendarList
          data-test="calendar-list-component"
          events={this.state.events}
          noEventsFound={this.state.noEventsFound}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { events: state.events };
}

function loadData(store) {
  return store.dispatch(fetchEvents());
}

export default {
  loadData,
  component: connect(mapStateToProps, { fetchEvents })(
    withStyles(styles)(Calendar)
  ),
};
