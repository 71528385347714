import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import globalStyle from "../../components/global-styles/style.css";

const InputBox = (props) => {
  return (
    <div className="input-box-wrapper center">
      <label
        className="inp flex align-end justify-center"
        style={{
          border: !props.error ? "1px solid #babcbf" : "2px solid red",
        }}
      >
        <input
          data-test={props["data-test"]}
          className="input-box-input"
          // id="inp"
          type={props.type}
          name={props.name}
          value={props.value}
          onChange={props.setValue}
          autoCapitalize={props.autoCapitalize}
          placeholder="&nbsp;"
          pattern={props.pattern}
          inputMode={props.inputMode}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              props.onKeyPress();
            }
          }}
        />
        <span className="label">{props.placeholder}</span>
        <span className="border"></span>
      </label>
    </div>
  );
};

InputBox.defaultProps = {
  "data-test": "input-data-test",
  type: "text",
  name: "input",
  placeholder: "placeholder",
  autoCapitalize: "on",
  pattern: "",
  inputMode: "",
  onKeyPress: () => null,
};

export default withStyles(styles, globalStyle)(InputBox);
