import React from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import styles from "./style.css";
import Hero from "./components/hero";
import Message from "./components/message";
import Form from "./components/form";

const Prayer = () => {
  document.title = "Prayer | UK";
  return (
    <div>
      <Hero />
      <Message />
      <Form />
    </div>
  );
};

export default {
  component: withStyles(styles)(Prayer),
};
