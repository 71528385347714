import React, { useState } from "react";
import withStyles from "isomorphic-style-loader/withStyles";
import locations from "./locations.json";
import globalStyle from "../../../../components/global-styles/style.css";
import styles from "./style.css";
import pastRoroImg from "../../../../../resources/images/pastors/Past-Roro-and-Wife.jpg";
import pastAhisImg from "../../../../../resources/images/pastors/ashis.jpg";

const LocationsSection = ({ lang }) => {
  const [location, setLocation] = useState(lang.items[0]);

  function handleLocationClick(idx) {
    if (locations[idx].city === location.city) return;
    setLocation(lang.items[idx]);
  }

  return (
    <section className="hp-location-wrapper flex center column" id="locations">
      <div className="hp-location-title center column">
        <h1 className="raleway-typography">{lang.title}</h1>
        <p className="raleway-typography">{lang.paragraph}</p>
      </div>
      <div className="hp-location-content flex align-start justify-center">
        <div className="hp-location-list">
          {lang.items.map((loc, idx) => (
            <div
              key={idx}
              className="hp-location-list-item flex align-center"
              style={{
                borderBottom:
                  idx === locations.length - 1 ? "1px solid #eeeeee" : "unset",
              }}
              onClick={() => handleLocationClick(idx)}
            >
              <p
                className={`raleway-typography ${
                  loc.city !== location.city ? "hp-location-list-hover" : ""
                }`}
                style={{
                  color: loc.city === location.city ? "black" : "#828282",
                }}
              >
                {loc.city}
              </p>
            </div>
          ))}
        </div>
        <div className="hp-location-list-content">
          <h1 className="raleway-typography">{location.city}</h1>
          {/* ### Uncomment to display important location announcements ### */}
          {/* <div className="hp-location-important-info">
            <h3 className="raleway-typography">
              {location.importantInfo.title}
            </h3>
            <h5>
              {location.importantInfo.paragraph1}
              <br />
              {location.importantInfo.paragraph2}
            </h5>
          </div> */}
          <p className="raleway-typography">{location.serviceTime}</p>
          <p className="raleway-typography">{location.address}</p>
          {Object.keys(location.otherInfo).map((info, idx) => (
            <p key={idx} className="raleway-typography">
              {info}: {location.otherInfo[info]}
            </p>
          ))}

          <div className="hp-location-pastor flex center">
            <div className="hp-location-location-pastor-img center">
              <img
                src={
                  location.pastor.picture === "ashis.jpg"
                    ? pastAhisImg
                    : pastRoroImg
                }
                alt="pastor"
              />
            </div>
            <div className="hp-location-location-pastor-details flex justify-center column">
              <h4 className="raleway-typography">
                {location.pastor.locationPastor}
              </h4>
              <h3 className="raleway-typography">
                Past. {location.pastor.name}
              </h3>
              <a
                href={`mailto:${location.pastor.email}`}
                className="raleway-typography"
              >
                <span>
                  <img
                    alt="mail"
                    src={require("../../../../../resources/svg/mail.svg")}
                  />
                </span>
                {location.pastor.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withStyles(styles, globalStyle)(LocationsSection);
